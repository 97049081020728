import React from "react";

import ProfileForm from "./ProfileFormComponent/profileForm.component";
import coachCouls from "src/images/coach-couls.png";
import "src/app.css";
import "./profile.component.css";
import ProfileCheckbox from './ProfileCheckboxComponent/profileCheckbox.component';
import Loader from "src/Utils/loader";
import { Redirect } from "react-router-dom";
import { useQueryProfileInfos } from "../../store/profile/profile.service";


function Profile() {
  const { profileInfos, isLoading } = useQueryProfileInfos();

  /**
   * Permet de rediriger de la page Profil vers la page Stats seulement si on arrive sur le coach depuis go.comwatt.
   * Quand on navigue sur la page Profil en cliquant sur le menu en bas de page
   * on envoie une queryParam dans l'url pour empecher la redirection
   **/
  function redirectIfProfilAlreadyCreated() {

    const queryString = window.location.hash
    const urlParams = new URLSearchParams(queryString.split("/").pop());
    const isRedirectionToStats = urlParams.get("redirectionToStats") !== "false"

    if (profileInfos.profile_created_at && isRedirectionToStats) {
      const macAddress = sessionStorage.getItem("macAddress");
      return <Redirect to={"/Stats/" + macAddress} />
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {isLoading
        ? <Loader loading={true} />
        : (profileInfos
          ? <div>
            {redirectIfProfilAlreadyCreated()}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img className="coach-icon" src={coachCouls} alt="Coach Icon"></img>
              <p className="bubble left">
                Bonjour <b>{profileInfos.owner.firstname} {profileInfos.owner.lastname}</b> !<br></br>Je suis votre coach énergétique et on va essayer ensemble de réduire votre facture d'énergie.
                <br></br>Pour pouvoir vous conseiller au mieux, j'ai besoin d'en savoir un peu plus sur votre maison.
              </p>
            </div>
            <div className="ask">
              <p>Je souhaite recevoir des conseils personnalisés par email tous les mois ?</p>
              <ProfileCheckbox checkboxData={profileInfos} />
            </div>
            <ProfileForm profileData={profileInfos} />
          </div>
          : <span>Problème de récupération des données</span>
        )
      }
    </div>
  );
}

export default Profile;
