import React, { useReducer } from "react";

import "./community.component.css";
import "src/app.css";

import { ArrowA, ArrowB, ArrowC, ArrowD, ArrowE } from "src/Utils/podiumArrows";
import { FilterMenu } from "src/shared/FilterMenuComponent/filterMenu.component";
import Loader from "src/Utils/loader";
import TilesContainerComponent from "src/shared/TileComponent/tilesContainer.component";
import { useQueryCommunity } from "../../store/community/community.service";


const CONSUMPTION = { code: "consumption", color: "#F57F17", color2: "#ffd8b0", label: "Consommation" };
const PRODUCTION = { code: "production", color: "#ffd000", color2: "#fceca2", label: "Production" };
const ECONOMY = { code: "saving", color: "#97BF0D", color2: "#d2dbb4", label: "Economie" };
const EUROS = { code: "euros", color: "#6d6d6d", label: "euro", shortLabel: "€" };
const MWH = { code: "mwh", color: "#6d6d6d", label: "MWh", shortLabel: "MWh" };
const PERCENTAGE = { code: "percentage", color: "#6d6d6d", label: "pourcentage", shortLabel: "%" };

const FILTERS = {
  [CONSUMPTION.code]: [EUROS, MWH],
  [PRODUCTION.code]: [MWH, PERCENTAGE],
  [ECONOMY.code]: [EUROS, PERCENTAGE]
};

const SET_FILTER = "SET_FILTER";
const SET_SUB_FILTER = "SET_SUB_FILTER";

/** Handle Fitlers */
function reducer(state = INITIAL_STATE, { type, filter, subFilter }) {
  switch (type) {
    case SET_FILTER:
      const [, old_subfilter] = state;
      if (Object.keys(FILTERS).includes(filter.code)) {
        const subFilters = FILTERS[filter.code];
        state = [filter, subFilters.includes(old_subfilter) ? old_subfilter : subFilters[0]];
      } else {
        console.log("Unknown filter!");
      }
      break;
    case SET_SUB_FILTER:
      const [old_filter] = state;
      if (FILTERS[old_filter.code].includes(subFilter)) {
        state = [old_filter, subFilter];
      } else {
        console.log("Unknown subFilter!");
      }
      break;
    default:
      break;
  }
  return state;
}

const INITIAL_STATE = [CONSUMPTION, EUROS];

function CommunityComponent() {
  const [[filter, subFilter], dispatch] = useReducer(reducer, INITIAL_STATE);
  const { communityData: value, isLoading } = useQueryCommunity(filter.code, subFilter.code);


  return (
    <div className="subContent">
      <div>
        <div className="DashboardPanel">
          <FilterMenu
            activeFilter={filter.code}
            onClick={f => dispatch({ type: SET_FILTER, filter: f })}
            options={[CONSUMPTION, PRODUCTION, ECONOMY]}
          />
          <div className="chartContainer">
            <div className="chartTitle">
              {filter === CONSUMPTION && "Ma Consommation comparée aux foyers similaires*"}
              {filter === PRODUCTION && "Ma Production comparée à ma production théorique"}
              {filter === ECONOMY && "Mes économies par rapport à la moyenne française"}
              <br></br>
              <i style={{ fontSize: "12px" }}>
                {filter.label} {filter === PRODUCTION && "/ kWc "}
                Sur les 12 derniers mois
              </i>
            </div>
            <div className="UnitMesureFilter">
              <FilterMenu
                options={[EUROS, MWH, PERCENTAGE].filter(subf =>
                  FILTERS[filter.code].includes(subf)
                )}
                onClick={f => dispatch({ type: SET_SUB_FILTER, subFilter: f })}
                activeFilter={subFilter.code}
              />
            </div>
            {isLoading
              ? <Loader loading={true} />
              : (value && value.userData && value.userData !== 0)
                ? <div className="podiumContainer">
                  <div className="scale">
                    <ArrowA smallWidth={filter === CONSUMPTION}>
                      <span>
                        {filter === CONSUMPTION ? (
                          <i className="icon-chevron-left"></i>
                        ) : (
                          <i className="icon-chevron-right"></i>
                        )}{" "}
                        {value.communityData[0]} {subFilter.shortLabel}
                      </span>
                    </ArrowA>
                    <ArrowB smallWidth={filter === CONSUMPTION}>
                      <span>de {value.communityData[0]} à {value.communityData[1]} {subFilter.shortLabel}</span>
                    </ArrowB>
                    <ArrowC>
                      <span>de {value.communityData[1]} à {value.communityData[2]} {subFilter.shortLabel}</span>
                    </ArrowC>
                    <ArrowD smallWidth={filter === PRODUCTION || filter === ECONOMY}>
                      <span>de {value.communityData[2]} à {value.communityData[3]} {subFilter.shortLabel}</span>
                    </ArrowD>
                    <ArrowE smallWidth={filter === PRODUCTION || filter === ECONOMY}>
                      <span>
                        {filter === CONSUMPTION ? (
                          <i className="icon-chevron-right"></i>
                        ) : (
                          <i className="icon-chevron-left"></i>
                        )}{" "}
                        {value.communityData[3]} {subFilter.shortLabel}
                      </span>
                    </ArrowE>
                  </div>

                  {filter === CONSUMPTION
                    ? <div className="userPosition">

                      <div className={"userBase " + (value.userData < value.communityData[0] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>

                      <div className={"userBase " + (value.userData >= value.communityData[0] && value.userData < value.communityData[1] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>

                      <div className={"userBase " + (value.userData >= value.communityData[1] && value.userData < value.communityData[2] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>

                      <div className={"userBase " + (value.userData >= value.communityData[2] && value.userData < value.communityData[3] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>

                      <div className={"userBase " + (value.userData > value.communityData[3] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>

                    </div>
                    : <div className="userPosition">
                      <div className={"userBase " + (value.userData > value.communityData[0] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>
                      <div className={"userBase " + (value.userData <= value.communityData[0] && value.userData > value.communityData[1] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>
                      <div className={"userBase " + (value.userData <= value.communityData[1] && value.userData > value.communityData[2] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>
                      <div className={"userBase " + (value.userData <= value.communityData[2] && value.userData > value.communityData[3] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>
                      <div className={"userBase " + (value.userData <= value.communityData[3] ? "" : "invisible")}>
                        <div className="line"></div>
                        <div className="triangle-left"></div>
                        <div className="rect">
                          <span>{value.userData} {subFilter.shortLabel}</span>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                : <span>Nous ne disposons pas des données nécessaires au traitement de cette information.</span>
            }
            <div className="info">
              <span><i className="footNoteInsideChart">Si vous avez besoin de plus d'informations </i></span>
              <a alt="lien pour aide" target="_blank" rel="noopener noreferrer"
                href="https://my.comwatt.com/espace-utilisateur/kbpart/comprendre-indicateurs-cles-coaching/?autologin_code=P4TANn7zV9WwBPg3X1HifuVqihyJqGiv"><span className='icon-info'></span></a>
            </div>
          </div>
          {filter === CONSUMPTION &&
            <div className="footNoteOutsideChartContainer">
              <span className="footNoteOutsideChart"><b>* Foyers Similaires</b> : Foyers ayant des caractéristiques similaires au vôtre en terme de surface, habitants ...</span>
            </div>
          }
        </div>

        <TilesContainerComponent displayPage="community" />

      </div>
    </div>
  );
}

export default CommunityComponent;
