import React from 'react';

import betaIcon from "src/images/beta2.png";
import { getEnvironmentV3orV4 } from 'src/Utils/boxes.version';
import { useScreenResolution } from 'src/Utils/screen_resolution.hook';
import {
  NavContainer
  , NavUl
  , NavLi
  , NavText
  , Icon
  , StyledLink
  , BetaIcon
  , BoxTitle
  , NavBoxType
} from './style';

function getComwattUrl(identifier){
  const currentUrl = window.location.href;
  const environment = getEnvironmentV3orV4(currentUrl);

  return `${environment.CONSOLE_API_URL}${environment.BOX_IN_URL}` + identifier
}


function Nav() {
  const screen_resolution = useScreenResolution();
  const macAddress = sessionStorage.getItem("macAddress");

  return (
    <NavContainer>
      <NavUl>
        <NavLi>
          <StyledLink href={getComwattUrl(macAddress) + "/dashboard"}>
            <Icon className="icon-nav-dashboard" />
            <NavText screen_resolution={screen_resolution}> tableau de bord</NavText>
          </StyledLink>
        </NavLi>
        <NavLi>
          <StyledLink className="active" href="/#/Profile">
            <Icon className="icon-nav-support" />
            <NavText
              screen_resolution={screen_resolution}
              active={true}> coach</NavText>
            <BetaIcon src={betaIcon} alt="beta" />
          </StyledLink>

        </NavLi>
        <NavLi>
          <StyledLink href={getComwattUrl(macAddress) + "/devices"}>
            <Icon className="icon-nav-appareil" />
            <NavText screen_resolution={screen_resolution}> appareils</NavText>
          </StyledLink>
        </NavLi>
        <NavLi>
          <StyledLink href={getComwattUrl(macAddress) + "/config/parameters"}>
            <Icon className="icon-nav-config" />
            <NavText screen_resolution={screen_resolution}> configuration</NavText>
          </StyledLink>
        </NavLi>
        <NavBoxType screen_resolution={screen_resolution}>
          <BoxTitle>box edelia</BoxTitle>
        </NavBoxType>
      </NavUl>
    </NavContainer>
  );
}

export default Nav;