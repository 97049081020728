import { useQuery } from "react-query";

import WeatherApi from "./weather.api";
import { formatResponseToDailyPrevisions } from "src/screens/WeatherComponent/weather.utils";


function useQueryWeatherPrevisions() {
  const { data, ...query } = useQuery(
    ['useQueryWeatherPrevisions'],
    () => WeatherApi.getWeatherPrevisions(),
  );

  return {
    ...query,
    dailyPrevisions: formatResponseToDailyPrevisions(data)
  }
}

export {
  useQueryWeatherPrevisions
};