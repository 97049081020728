import React, { useState } from "react";

import { TileButton, TileButtonIcon } from "./tile";
import { openCenteredPopup, updateDescriptionWithValues } from "./tiles.utils"
import facebookIcon from "src/images/social-network-icons/facebook.png";
import twitterIcon from "src/images/social-network-icons/twitter.png";
import linkedInIcon from "src/images/social-network-icons/linkedin.png";

const ACTION_BUTTONS_LABELS = {
  seeAdvice: {label : "Voir conseils", icon : "icon-eye"},
  shareSocialNetworks: {label : "Partager sur les réseaux", icon : "icon-arrow-angle-down", iconUp: "icon-arrow-angle-up"},
  shareComwatteurs: {label : "Partager avec les Comwatteurs", icon : "icon-ico-communaute"},
  configure: {label: "Configurer", icon: "icon-equalizer2"},
  close: {label : "Ne plus afficher", icon : "icon-cross"}
}


function TileButtonComponent({ id, adviceLink, type, description, descriptionValue, closeTile }) {

  const [isOpenedSocialNetworkChoiceModal, setIsOpenedSocialNetworkChoiceModal] = useState(false);
  const url = "https://www.comwatt.com/projet-autoconsommation/simulateur-economies-energie/?utm_content=mainmenu";


  function openSelectedSocialNetworkPopup(e, url, description, descriptionValue) {
    let selectedSocialNetwork = e.currentTarget.getAttribute('alt');
    let shareUrl = "";

    switch (selectedSocialNetwork){
      case "facebook":
        shareUrl = "https://www.facebook.com/dialog/share?app_id=87741124305&href=" + url + "&quote=" + encodeURIComponent(updateDescriptionWithValues(description, descriptionValue));
        openCenteredPopup(shareUrl, "Partager sur Facebook", 640, 320);
        break;
      case "twitter":
        shareUrl = "https://twitter.com/intent/tweet?&url=" + url + "&text=" + encodeURIComponent(updateDescriptionWithValues(description, descriptionValue));
        openCenteredPopup(shareUrl, "Partager sur Instagram", 640, 320);
        break;
      case "linkedin":
        shareUrl = "https://www.linkedin.com/shareArticle?mini=true&url=" + url;
        openCenteredPopup(shareUrl, "Partager sur LinkedIn", 640, 320);
        break;
      default:
    }
}

  function onTileButtonClick() {
    switch (type){
      case "shareSocialNetworks" :
        setIsOpenedSocialNetworkChoiceModal(!isOpenedSocialNetworkChoiceModal);
        break;
      case "close":
        closeTile()
        break;
      default:
    }
  }


  return (
    <div style={{ flexGrow: 1 }}>
      <TileButton key={id} href={adviceLink} target="new" onClick={onTileButtonClick}>
        <TileButtonIcon className={isOpenedSocialNetworkChoiceModal ? ACTION_BUTTONS_LABELS[type].iconUp : ACTION_BUTTONS_LABELS[type].icon}></TileButtonIcon>
        <span>{ACTION_BUTTONS_LABELS[type].label}</span>
      </TileButton>
      {isOpenedSocialNetworkChoiceModal &&
        <div className="socialNetworkChoiceModal">
          <div className="closeSocialNetworkChoiceModal" onClick={onTileButtonClick}><i className="icon-cross"></i></div>
          <div className="socialNetworkIconsContainer">
            <img src={facebookIcon} alt="facebook" onClick={(e) => openSelectedSocialNetworkPopup(e, url, description, descriptionValue)}></img>
            <img src={twitterIcon} alt="twitter" onClick={(e) => openSelectedSocialNetworkPopup(e, url, description, descriptionValue)}></img>
            <img src={linkedInIcon} alt="linkedin" onClick={(e) => openSelectedSocialNetworkPopup(e, url, description, descriptionValue)}></img>
          </div>
        </div>
      }
    </div>
  )

}

export { TileButtonComponent };