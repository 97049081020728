import is from 'is_js';

const I18n = {t: text => text}

function default_validation(value) {
    return (is.string(value) && is.not.empty(value)) || is.number(value);
}

function is_object_with_every_properties_filled(object) {
    return is.object(object) && Object.values(object).every(default_validation);
}

function is_required(value) {
    return [default_validation, is_object_with_every_properties_filled].reduce(
        (is_valid, predicate) => is_valid || predicate(value)
        , false
    ) || I18n.t('this field is required');
}

// http://stackoverflow.com/a/46181/3483086
const is_email_regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i; // eslint-disable-line max-len

function is_email(value) {
    return (is.string(value) && is_email_regex.test(value)) || I18n.t('The email is not valid');
}

// http://stackoverflow.com/a/31934362/3483086
const is_password_regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
function is_password(value) {

    return (is.string(value) && is_password_regex.test(value))
        || I18n.t('Password should contain at least 8 characters with a capital letter, a lowercase and a number');
}

// http://stackoverflow.com/a/2044909/3483086
const is_name_regex = /^([ \u00c0-\u01ffa-zA-Z'-])+$/;
function is_name(value) {
    return (is.string(value) && is_name_regex.test(value)) || I18n.t('Should be formed only by letters');
}

function is_number(value) {
    return (is.string(value) && is.not.empty(value) && is.number(+value))
        || is.number(value)
        || I18n.t('number expected');
}

function is_checked(value) {
    return value ? value : '';
}


export {
    is_required
    , is_checked
    , is_email
    , is_name
    , is_number
    , is_password
};
