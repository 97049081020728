import React from "react";

import "./weatherInterval.component.css";
import  { WEATHER_ICONS, COLOR_BY_PRICE_INTERVAL }  from "src/screens/WeatherComponent/weather.utils";


function WeatherIntervalComponent({ dayInterval }) {

  return (
    <div className="intervalPrevision">
      <div className="hour">{dayInterval.startHourInterval}h</div>
      <div className="weather">
        <img src={WEATHER_ICONS[dayInterval.weather.value]} alt={dayInterval.weather.value}></img>
      </div>
      <div className={COLOR_BY_PRICE_INTERVAL[dayInterval.price]}>€</div>
    </div>
  )
}

export default WeatherIntervalComponent;