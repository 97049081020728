import styled from '@emotion/styled';

import { BIG_SCREEN } from "../Utils/screen_resolution.hook";

const ArrowA = styled('div')(({ smallWidth }) => (
  {
    background: '#45b802',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginTop: '14px',
    position: 'relative',
    width: smallWidth ? '80px' : '200px',
    [BIG_SCREEN]: {
      width: smallWidth ? '150px' : '350px',
      height: '40px',
      marginTop: '20px',
    },

    ':after': {
      borderLeft: '10px solid #45b802',
      borderBottom: '15px solid transparent',
      borderTop: '15px solid transparent',
      content: '""',
      top: '0',
      left: smallWidth ? '80px' : '200px',
      position: 'absolute',
      [BIG_SCREEN]: {
        left: smallWidth ? '150px' : '350px',
        borderLeft: '17px solid #45b802',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent'
      },
    }
  }
));

const ArrowB = styled('div')(({ smallWidth }) => (
  {
    background: '#97bf0d',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginTop: '14px',
    position: 'relative',
    width: smallWidth ? '110px' : '170px',
    [BIG_SCREEN]: {
      width: smallWidth ? '200px' : '300px',
      height: '40px',
      marginTop: '20px',
    },

    ':after': {
      borderLeft: '10px solid #97bf0d',
      borderBottom: '15px solid transparent',
      borderTop: '15px solid transparent',
      content: '""',
      top: '0',
      left: smallWidth ? '110px' : '170px',
      position: 'absolute',
      [BIG_SCREEN]: {
        left: smallWidth ? '200px' : '300px',
        borderLeft: '17px solid #97bf0d',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent'
      },
    }
  }
));

const ArrowC = styled('div')(
  {
    background: 'rgb(255, 208, 0)',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginTop: '14px',
    position: 'relative',
    width: '140px',
    [BIG_SCREEN]: {
      width: '250px',
      height: '40px',
      marginTop: '20px',
    },

    ':after': {
      borderLeft: '10px solid rgb(255, 208, 0)',
      borderBottom: '15px solid transparent',
      borderTop: '15px solid transparent',
      content: '""',
      top: '0',
      left: '140px',
      position: 'absolute',
      [BIG_SCREEN]: {
        left: '250px',
        borderLeft: '17px solid rgb(255, 208, 0)',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent'
      },
    }
  }
);

const ArrowD = styled('div')(({ smallWidth }) => (
  {
    background: 'rgb(255, 136, 0)',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginTop: '14px',
    position: 'relative',
    width: smallWidth ? '110px' : '170px',
    [BIG_SCREEN]: {
      width: smallWidth ? '200px' : '300px',
      height: '40px',
      marginTop: '20px',
    },

    ':after': {
      borderLeft: '10px solid rgb(255, 136, 0)',
      borderBottom: '15px solid transparent',
      borderTop: '15px solid transparent',
      content: '""',
      top: '0',
      left: smallWidth ? '110px' : '170px',
      position: 'absolute',
      [BIG_SCREEN]: {
        left: smallWidth ? '200px' : '300px',
        borderLeft: '17px solid rgb(255, 136, 0)',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent'
      },
    }
  }
));

const ArrowE = styled('div')(({ smallWidth }) => (
  {
    background: 'rgb(230, 5, 5)',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginTop: '14px',
    position: 'relative',
    width: smallWidth ? '80px' : '200px',
    [BIG_SCREEN]: {
      width: smallWidth ? '150px' : '350px',
      height: '40px',
      marginTop: '20px',
    },

    ':after': {
      borderLeft: '10px solid rgb(230, 5, 5)',
      borderBottom: '15px solid transparent',
      borderTop: '15px solid transparent',
      content: '""',
      top: '0',
      left: smallWidth ? '80px' : '200px',
      position: 'absolute',
      [BIG_SCREEN]: {
        left: smallWidth ? '150px' : '350px',
        borderLeft: '17px solid rgb(230, 5, 5)',
        borderBottom: '20px solid transparent',
        borderTop: '20px solid transparent'
      },
    }
  }
));

// const ArrowUser = styled.div`
//     background: #40413F;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     height: 30px;
//     margin-top: 10px;
//     position: relative;
//     width: 63px;

//     &:after {
//         border-right: 10px solid #40413F;
//         border-bottom: 15px solid transparent;
//         border-top: 15px solid transparent;
//         content: "";
//         height: 0;
//         top: 0;
//         left: -10px;
//         position: absolute;
//     }
// `;

export {
  ArrowA,
  ArrowB,
  ArrowC,
  ArrowD,
  ArrowE,
  // ArrowUser
};