import React from 'react';

import { Select } from 'src/Utils/input.component';


const ENERGY_SOURCES = {
    electric: 'Électrique',
    gas: 'Gaz',
    fuel: 'Fioul',
    wood: 'Bois',
    thermodynamic: 'Thermodynamique'
};


function mapEnergySourcesToOptions(energySources) {
    return Object.entries(energySources)
        .map(([value, text]) => ({ value, text }))
}

function SelectHotWaterKind({ options = mapEnergySourcesToOptions(ENERGY_SOURCES), ...props }) {
    return (
        <Select
            label="type de chauffe-eau"
            options={options}
            {...props}
        />
    )
}

export { SelectHotWaterKind };