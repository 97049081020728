import React from 'react';

import { Select } from 'src/Utils/input.component';

const INVERTER_TYPES = {
    micro: 'Micro',
    central: 'Central sans optimiseurs',
    optimizer: 'Central avec optimiseurs'
};

function mapInverterTypesToOptions(inverterTypes) {
    return Object.entries(inverterTypes)
        .map(([value, text]) => ({ value, text }))
}

function SelectInverterType({ options = mapInverterTypesToOptions(INVERTER_TYPES), ...props }) {

    return (
        <Select
            label="Type d'onduleur"
            options={options}
            {...props}
        />
    )
}

export { SelectInverterType };