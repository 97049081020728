import is from 'is_js';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';

import { is_required } from './input.utils';
import { usePrevious } from './previous.hook';

function set_hidden_properties(object, properties) {
    Object.defineProperties(object, Object.entries(properties).reduce(
        (prototype, [key, props]) => ({
            ...prototype
            , [key]: {
                enumerable: false
                , configurable: false
                , ...props
            }
        })
        , {}
    ));
}

function has_validation_status_changed(previous_state, next_state) {
    return previous_state.is_valid !== next_state.is_valid
        || previous_state.error_list.length !== next_state.error_list.length
        || previous_state.error_list.some(
            (error, index) => error !== next_state.error_list[index]
        );
}

function useForm(initial_values = {}, { disabled = false, validate_pristine = false } = {}) {
    const [values, set_values] = useState(initial_values);
    const [, set_fake_state] = useState(0);
    const field_list_touched = useRef([]);
    const field_list = useRef([]);

    const handle_submit = useCallback(function handle_submit(callback) {
        return event => {
            event.preventDefault();
            field_list_touched.current = [];
            callback(values, event);
        };
    }, [values]);

    const useField = useCallback(function useField(name) {
        const has_changed = useRef(false);
        const validation = useRef({
            is_subject_to_validation: true
            , required: false
            , is_valid: true
            , is_invalid: false
            , is_dirty: field_list_touched.current.includes(name)
            , error_list: []
        });
        const check_list = useRef([]);
        let check_count = 0;
        useEffect(() => {
            return () => {
                field_list.current = field_list.current
                    .filter(field => field.name !== name);
                set_fake_state(n => n + 1);
            };
        }, [name]);

        const field_value = values[name];

        const validate = useCallback(function validate(value) {
            const { is_valid, error_list } = check_list.current.reduce(
                (state, validator) => {
                    const error = validator(value, values);
                    if (is.string(error)) {
                        return {
                            is_valid: false
                            , error_list: state.error_list.concat(error)
                        };
                    }
                    return state;
                }
                , { is_valid: true, error_list: [] }
            );
            const previous_validation = validation.current;
            validation.current = {
                ...validation.current
                , required: check_list.current.includes(is_required)
                , is_valid
                , is_invalid: !is_valid
                , is_dirty: field_list_touched.current.includes(name)
                , error_list
            };
            if (has_validation_status_changed(previous_validation, validation.current)) {
                set_fake_state(n => n + 1);
            }
        }, [name]);

        const field = {
            name
            , value: is.not.existy(field_value) ? '' : field_value
            , disabled
            , onChange: useCallback(({ target: { value } }, is_triggered_by_user = true) => {
                if (is_triggered_by_user) {
                    field_list_touched.current = field_list_touched.current
                        .filter(element => element !== name)
                        .concat(name);
                }
                validate(value);
                has_changed.current = true;
                set_values(form_values => ({ ...form_values, [name]: value }));
            }, [name, validate])
            , 'data-validation': validation.current
        };

        const previous_value = usePrevious(field_value, field_value);
        useEffect(() => {
            if (!has_changed.current) {
                if (previous_value !== field_value) {
                    validate(field_value);
                }
            }
            else {
                has_changed.current = false;
            }
        }, [field_value, previous_value, validate]);

        function check(predicate) {
            if (!check_list.current.includes(predicate)) {
                if (check_count <= check_list.current.length) {
                    check_list.current[check_count] = predicate;
                }
                else {
                    check_list.current = [...check_list.current, predicate];
                }
                validate(field.value);
            }
            check_count += 1;
            return field;
        }

        set_hidden_properties(
            field
            , {
                is_valid: {
                    get() {
                        return validation.current.is_valid;
                    }
                }
                , is_invalid: {
                    get() {
                        return validation.current.is_invalid;
                    }
                }
                , check: {
                    value: check
                }
            }
        );

        field_list.current = field_list.current
            .filter(element => element.name !== name)
            .concat(field);

        return Object.assign(field, { 'data-validation': validation.current });
    }, [disabled, values]);

    return useMemo(() => ({
        useField
        , is_not_submittable: () => disabled
            || (!validate_pristine && field_list_touched.current.length === 0)
            || field_list.current.some(field => field.is_invalid)
        , field_list_touched() {
            return field_list_touched.current;
        }
        , values
        , disabled
        , set_values
        , handle_submit
    }), [disabled, handle_submit, useField, validate_pristine, values]);
}

export { useForm };
