import { useEffect, useRef } from 'react';

function usePrevious(value, initial_value) {
    const ref = useRef(initial_value);

    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    return ref.current;
}

export { usePrevious };
