import React from "react";

import { getEnvironmentV3orV4 } from "src/Utils/boxes.version";
import "./errorPages.component.css";


function Page401() {
  const consoleApiUrl = getEnvironmentV3orV4(window.location.href).CONSOLE_API_URL;
  const toLogin = `${consoleApiUrl}#/login/`;

  return (
    <div className="infoCard">
      <div className="titleCard">
        Authentification requise
      </div>
      <div className="detailCard">
        <p>Vous ne pouvez pas accéder à cette page.<br></br>
          Merci de vous authentifier avec votre compte Comwatt.</p>
      </div>
      <a className="linkButton" href={toLogin}>Ok</a>
    </div>
  );
}

export default Page401;