import React from "react";

import comwattLogo from "src/images/logo-comwatt-inverted-transparent-bg-cropped.png";
import "./header.component.css";

function Header() {
  let statusCode; // à voir comment le récupérer

  return (
    <header className="MainHeader">
      <div className="MenuIconContainer">
        <span
          className="icon-navicon MenuIcon"
        ></span>
      </div>
      <div className="ComwattLogoContainer">
        <img src={comwattLogo} alt="Comwatt"></img>
      </div>
      <div className="IconHelp">
        {statusCode === 200 &&
          <a
            href="https://my.comwatt.com/espace-utilisateur/kbpart/?autologin_code=P4TANn7zV9WwBPg3X1HifuVqihyJqGiv"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="icon-nav-info"></span>
          </a>
        }
      </div>
    </header>
  );
}

export default Header;
