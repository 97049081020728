import { useQuery, useMutation } from "react-query";

import TilesApi from "./tiles.api";
import { formatResponseToTilesList } from "src/shared/TileComponent/tiles.utils"


function useQueryTiles() {
  const { data, ...query } = useQuery(
    ['useQueryTiles'],
    () => TilesApi.getTiles(),
  );

  return {
    ...query,
    tiles: formatResponseToTilesList(data)
  }
}

function useMutationDeleteTile(options) {
  return useMutation(
    ['useMutationDeleteTile'],
    (tileId) => TilesApi.deleteTile(tileId),
    options
  );
}

export {
  useQueryTiles,
  useMutationDeleteTile
};