import React from 'react';

import { Select } from 'src/Utils/input.component';


const SOLAR_ORIENTATIONS = {
    south: 'Sud',
    south_east: 'Sud-Est',
    south_west: 'Sud-Ouest',
    east: 'Est',
    west: 'Ouest',
    east_west: 'Est-Ouest (toiture 2 pans)',
    east_south_west: 'Est-Sud-Ouest (toiture 3 pans)'
};


function mapSolarOrientationsToOptions(solarOrientations) {
    return Object.entries(solarOrientations)
        .map(([value, text]) => ({ value, text }))
}

function SelectSolarOrientation({ options = mapSolarOrientationsToOptions(SOLAR_ORIENTATIONS), ...props }) {
    return (
        <Select
            label="orientation des panneaux solaires"
            options={options}
            {...props}
        />
    )
}

export { SelectSolarOrientation };