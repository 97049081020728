import React from "react";

import "./errorPages.component.css";


function Page500() {

  return (
    <div className="infoCard">
      <div className="titleCardWarning">Connexion impossible</div>
      <div className="detailCard">
        <p>Problème de connexion au serveur.<br></br>
          Merci de réessayer ultérieurement.
        </p>
      </div>
    </div>
  );
}

export default Page500;
