import React from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import "./tile.component.css";

import { TileButtonComponent } from './tileButton.component';
import {
    TileMainContainer,
    TileContainer,
    TileDescription,
    TileHeader,
    IconHeader,
    TitleHeader,
    TileMainContent,
    TileContent,
    IconDescription,
    TileButtonContainer,
    TileCloseButton
} from "./tile";
import { updateDescriptionWithValues } from "./tiles.utils";
import { useMutationDeleteTile } from "../../store/tiles/tiles.service";


const HEADER_ICONS_BY_CATEGORY = {
    bad: "icon-ico-alerte", // triangle avec !
    good: "icon-happy", // smiley content
    infos: "", // à voir
    alert: "",
    advice: "icon-ico-conseil", // ampoule
}

const DESCRIPTION_ICONS_BY_TYPE = {
    consumption: "icon-ico-elec",
    production: "icon-ico-sun",
    savings: "icon-ico-economie",
    minPower: "icon-nav-deconexion",
    settings: "icon-equalizer2",
    laveVaisselle: "icon-ap-lavelinge"
}

const COLORS = {
    green: "#719008",
    orange: "#EC6707",
    yellow: "#FFC107"
}

const COLOR_BY_CATEGORY = {
    bad: COLORS.orange,
    good: COLORS.green,
    infos: "",
    alert: "red",
    advice: COLORS.yellow,
}


function TileComponent({ id, title, description, type, category, actions, descriptionValue }) {
    const queryClient = useQueryClient();

    const { mutate: deleteTile } = useMutationDeleteTile({
        onError: () => toast.warning("Il y a eu une erreur, merci d'essayer à nouveau plus tard."),
        onSuccess: () => queryClient.invalidateQueries('useQueryTiles')
    });

    function closeTile() {
        deleteTile(id);
    }

    return (
        <div>
            <TileMainContainer>
                <TileContainer>
                    <TileHeader>
                        <IconHeader color={COLOR_BY_CATEGORY[category]}>
                            <span className={HEADER_ICONS_BY_CATEGORY[category]}></span>
                        </IconHeader>
                        <TitleHeader color={COLOR_BY_CATEGORY[category]}>{title}</TitleHeader>
                        <TileCloseButton className="icon-cross" onClick={closeTile}></TileCloseButton>
                    </TileHeader>
                    <TileMainContent>
                        <TileContent>
                            <IconDescription><span className={DESCRIPTION_ICONS_BY_TYPE[type]}></span></IconDescription>
                            <TileDescription>{updateDescriptionWithValues(description, descriptionValue)}</TileDescription>
                        </TileContent>
                    </TileMainContent>
                    <TileButtonContainer>
                        {actions.map(action => (
                            <TileButtonComponent
                                key={action.id}
                                id={action.id}
                                adviceLink={action.adviceLink}
                                type={action.value}
                                description={description}
                                descriptionValue={descriptionValue}
                            />
                        ))}
                        <TileButtonComponent
                            type="close"
                            closeTile={closeTile}
                        />
                    </TileButtonContainer>
                </TileContainer>
            </TileMainContainer>

        </div>
    )
}
export { TileComponent };