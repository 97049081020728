import React, { useReducer } from "react";

import "./stats.component.css";
import "src/app.css";

import { FilterMenu } from "src/shared/FilterMenuComponent/filterMenu.component";
import ChartComponent from "./chart.component";
import TilesContainerComponent from "src/shared/TileComponent/tilesContainer.component";
import DataExportModalComponent from "src/shared/DataExportModalComponent/dataExportModal.component";
import Loader from "src/Utils/loader";
import { SmallButton } from 'src/Utils/button';
import useModal from "src/Utils/modal.hook";
import isChartDataExist from './stats.utils';
import { useQueryStats } from "../../store/stats/stats.service";


const CONSUMPTION = { code: "consumption", color: "#F57F17", color2: "#ffd8b0", label: "Consommation" };
const PRODUCTION = { code: "production", color: "#ffd000", color2: "#fceca2", label: "Production" };
const ECONOMY = { code: "saving", color: "#97BF0D", color2: "#d2dbb4", label: "Economie" };
const EUROS = { code: "euros", color: "#6d6d6d", label: "euro", shortLabel: "€" };
const KWH = { code: "kwh", color: "#6d6d6d", label: "kWh", shortLabel: "kWh" };
const PERCENTAGE = { code: "percentage", color: "#6d6d6d", label: "pourcentage", shortLabel: "%" };

const FILTERS = {
  [CONSUMPTION.code]: [EUROS, KWH],
  [PRODUCTION.code]: [EUROS, KWH],
  [ECONOMY.code]: [EUROS, PERCENTAGE]
};

const SET_FILTER = "SET_FILTER";
const SET_SUB_FILTER = "SET_SUB_FILTER";

/** Handle Filters */
function reducer(state = INITIAL_STATE, { type, filter, subFilter }) {
  switch (type) {
    case SET_FILTER:
      const [, old_subfilter] = state;
      if (Object.keys(FILTERS).includes(filter.code)) {
        const subFilters = FILTERS[filter.code];

        state = [filter, subFilters.includes(old_subfilter) ? old_subfilter : subFilters[0]];
      } else {
        console.log("Unknown filter!");
      }
      break;
    case SET_SUB_FILTER:
      const [old_filter] = state;
      if (FILTERS[old_filter.code].includes(subFilter)) {
        state = [old_filter, subFilter];
      } else {
        console.log("Unknown subFilter!");
      }
      break;
    default:
      break;
  }
  return state;
}

const INITIAL_STATE = [CONSUMPTION, EUROS];

function StatsComponent() {
  const { isOpened, toggle } = useModal();
  const [[filter, subFilter], dispatch] = useReducer(reducer, INITIAL_STATE);
  const { statsData, isLoading } = useQueryStats(filter.code, subFilter.code);

  return (
    <div className="subContent">
      <div>
        <div className="DashboardPanel">
          <SmallButton className="dataExportButton" onClick={toggle}>Télécharger mes données</SmallButton>
          <DataExportModalComponent isOpened={isOpened} closeModal={toggle}/>

          <FilterMenu
            options={[CONSUMPTION, PRODUCTION, ECONOMY]}
            onClick={f => dispatch({ type: SET_FILTER, filter: f })}
            activeFilter={filter.code}
          />
          <div className="chartContainer">
            <div className="chartTitle">
              {(filter === CONSUMPTION && subFilter === EUROS) ? "Dépenses réalisées"
                : filter.label + " Totale"}
              <div></div>
            </div>
            <div className="UnitMesureFilter">
              <FilterMenu
                options={[EUROS, KWH, PERCENTAGE].filter(subf =>
                  FILTERS[filter.code].includes(subf)
                )}
                onClick={f => dispatch({ type: SET_SUB_FILTER, subFilter: f })}
                activeFilter={subFilter.code}
              />
            </div>
            <div>
              {isLoading ? (
                <Loader loading={true} />
              ) : isChartDataExist(statsData) ? (
                <ChartComponent data={statsData.dataset} months={statsData.months} filterSelected={filter} unitFilterSelected={subFilter}></ChartComponent>
              ) : (
                <span>Vos données ne sont pas encore accessibles.</span>
              )}
            </div>
            <div className="info">
              <span><i className="footNoteInsideChart">Si vous avez besoin de plus d'informations </i></span>
              <a alt="lien pour aide" target="_blank" rel="noopener noreferrer"
                href="https://my.comwatt.com/espace-utilisateur/kbpart/comprendre-indicateurs-cles-coaching/?autologin_code=P4TANn7zV9WwBPg3X1HifuVqihyJqGiv"><span className='icon-info'></span></a>
            </div>
          </div>
        </div>
        <TilesContainerComponent displayPage="stats" />
      </div>
    </div>
  );
}

export default StatsComponent;
