import { useMutation } from "react-query";

import UserOpinionApi from "./user-opinion.api";

function useMutationUserOpinion(options) {
  return useMutation(
    ['useMutationUserOpinion'],
    (payload) => UserOpinionApi.postUserOpinion(payload),
    options
  );
}

export {
  useMutationUserOpinion,
};