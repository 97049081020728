import React from "react";

import "./weatherDay.component.css";
import  { WEATHER_ICONS, COLOR_BY_PRICE_DAY }  from "src/screens/WeatherComponent/weather.utils";


function WeatherDayComponent({ dailyPrevision, selectedDay, displayDayDetail }) {

  const classNames = {
    dailyPrevision: selectedDay === dailyPrevision.date ? 'selectedDailyPrevision' : 'dailyPrevision',
    day: selectedDay === dailyPrevision.date ?  'selectedDay' : 'day',
    prices: selectedDay === dailyPrevision.date ? 'selectedPrices' : 'prices',
  }

  return (
    <div className={classNames.dailyPrevision}>
      <div className={classNames.day}>
        <div>{dailyPrevision.day}</div>
        <div>{dailyPrevision.date}</div>
      </div>

      <div className="weatherIndicator">
        <img src={WEATHER_ICONS[dailyPrevision.weather.value]} alt={dailyPrevision.weather.value}></img>
        <div>{dailyPrevision.weather.name}</div>
      </div>

      <div className={classNames.prices}>
        <div className="firstTime">
          <div>Matin</div>
          {dailyPrevision.morningPrice
            ? <div className={COLOR_BY_PRICE_DAY[dailyPrevision.morningPrice]}>€</div>
            : <div className="notProvidedPrice">NC</div>
          }
        </div>
        <div className="secondTime">
          <div>Après-midi</div>
          {dailyPrevision.afternoonPrice
            ? <div className={COLOR_BY_PRICE_DAY[dailyPrevision.afternoonPrice]}>€</div>
            : <div className="notProvidedPrice">NC</div>
          }
        </div>
        <div className="thirdTime">
          <div>Nuit</div>
          {dailyPrevision.nightPrice
            ? <div className={COLOR_BY_PRICE_DAY[dailyPrevision.nightPrice]}>€</div>
            : <div className="notProvidedPrice">NC</div>
          }
        </div>
      </div>

      <div className="detail" onClick={() => displayDayDetail(dailyPrevision.date)}>
        <span>Détail</span>
        {selectedDay === dailyPrevision.date
          ? <span className="icon-arrow-angle-up"></span>
          : <span className="icon-arrow-angle-down"></span>
        }
      </div>
    </div>
  )
}

export default WeatherDayComponent;