function getEnvironmentV3orV4(currentUrl) {
  if(currentUrl.includes('preprodv4') || currentUrl.includes('energy')){
    return {
      API_URL : `${process.env.REACT_APP_API_URL_V4}`,
      CONSOLE_API_URL: `${process.env.REACT_APP_CONSOLE_V4_API_URL}`,
      BOX_IN_URL: '#/sites/'
    }
  }
  else {
    return {
      API_URL : `${process.env.REACT_APP_API_URL_V3}`,
      CONSOLE_API_URL: `${process.env.REACT_APP_CONSOLE_V3_API_URL}`,
      BOX_IN_URL: '#/boxes/'
    }
  }
}

export { getEnvironmentV3orV4 };