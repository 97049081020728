import React from "react";

import "src/app.css";
import "./tilesContainer.component.css";
import coachCouls from "src/images/coach-couls.png";

import { TileComponent } from "./tile.component";
import Loader from "src/Utils/loader";
import { useQueryTiles } from "../../store/tiles/tiles.service";


function TilesContainerComponent({ displayPage }) {

  const { tiles, isLoading } = useQueryTiles();

  return (
    <div className="DashboardTiles">
          {!isLoading ?
          (tiles.length > 0 && tiles.filter(tile => tile.displayPage === displayPage).length !== 0
            ?
            tiles.filter(tile => tile.displayPage === displayPage).map(tile => (
              <TileComponent
                key={tile.id}
                {...tile}
              />
            ))
            : <div className="noTilesMessage">
                <img className="noTilesImage" src={coachCouls} alt="Coach Icon"></img>
                <div className="noTilesDescription">
                  Vous n'avez actuellement pas de tuile parce que vous manquez de données
                  ou parce que vous avez effacé vos tuiles. Attendez le mois prochain
                  pour voir apparaître des tuiles !
                </div>
              </div>)
          : <Loader loading={true} /> }
    </div>
  );
}

export default TilesContainerComponent;
