import { useState } from "react";

const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened(!isOpened);
  }

  return {
    isOpened,
    toggle
  };
};

export default useModal;