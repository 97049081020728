import styled from '@emotion/styled';
import { css } from '@emotion/core'

const dynamicStyle = props =>
    css`
    color: ${props.color};
  `

const TileMainContainer = styled.div`
    opacity: 1;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 15px;
    text-align: center;
`;

const TileContainer = styled.div`
    border-style: solid;
    border-color: #ededed;
    border-width: 0;
`;

const TileHeader = styled.div`
    background: #ededed;
    display: flex;
    justify-content: space-between;
    height: 32px;
`;

const IconHeader = styled.div`
    ${dynamicStyle};
    width: 32px;
    margin-top: 5px;
    font-size: 20px;
    background: #ededed;
`;

const TitleHeader = styled.p`
    ${dynamicStyle};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: uppercase;
    line-height: 32px;
    margin: 0;
    flex: 1 1;
    font-weight: 700;
`;

const TileMainContent = styled.div`
    position: relative;
    background: #f6f6f6;
    height: 100%;
    overflow: hidden;
`;

const TileContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    -moz-box-align: center;
    align-items: center;
    padding: 12px;
    height: 100%;
`;

const IconDescription = styled.span`
    font-size: 30px;
    margin: 6px 0 0 5px;
`;

const TileDescription = styled.p`
    text-align: left;
    overflow: hidden;
    line-height: 1.2;
    margin: 0 0 0 20px;
`;

/** Croix en haut à droite de la tuile */
const TileCloseButton = styled.div`
    padding-right: 9px;
    padding-top: 9px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
`;
/***************************************/

const TileButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const TileButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #97bf0d;
    border-top: none;
    border-bottom: none;
    border-right: 1px solid #abd126;
    border-left: 1px solid #abd126;
    height: 32px;
    width: 100%;
    color: #fff;
    font-size: 11px;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        border: 1px solid #dbf092;
        background: #dbf092;
        color: #3a3a39;
        text-decoration: none;
      }
`;

const TileButtonIcon = styled.span`
    font-size: 14 px;
    margin-right: 6px;
    margin-bottom: 2px;
`;

export {
    TileMainContainer,
    TileContainer,
    TileDescription,
    IconDescription,
    TileHeader,
    IconHeader,
    TitleHeader,
    TileMainContent,
    TileContent,
    TileButtonContainer,
    TileButton,
    TileCloseButton,
    TileButtonIcon
};
