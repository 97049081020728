import React from "react";
import styled from "@emotion/styled/macro";

import "./filterMenu.component.css";
import { SMALL_SCREEN } from "src/Utils/screen_resolution.hook";

const ButtonFilter = styled("button")(({ selected, fontSize, primaryColor }) => ({
  fontSize: fontSize ? fontSize : "11px",
  [SMALL_SCREEN]: { fontSize: "11.5px" },
  textTransform: "uppercase",
  border: "1px solid #c3c3c3",
  cursor: "pointer",
  borderRadius: "15px",
  margin: "4px 4px",
  padding: "2px 6px",
  color: selected ? "#fff" : "5e5e5e",
  borderColor: selected ? primaryColor : "",
  background: selected ? primaryColor : "white"
}));

function FilterMenu({ options, onClick, activeFilter, style }) {
  return (
    <div className="menuFilters" style={style}>
      <ul className="menuFilters_ul">
        <li className="menuFilters_li">
          {options.map((filter) => (
            <ButtonFilter
              key={filter.code}
              selected={filter.code === activeFilter}
              fontSize={12}
              primaryColor={filter.color}
              onClick={() => onClick(filter)}
            >
              {filter.label}
            </ButtonFilter>
          ))}
        </li>
      </ul>
    </div>
  );
}

export { FilterMenu, ButtonFilter };
