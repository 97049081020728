import { fetchRequest } from "../api";

export default class ProfileApi {

  static getElectricityProviders = () => {
    return fetchRequest("public/electricity-providers","GET");
  }

  static getElectricityContractKinds = () => {
    return fetchRequest("public/electricity-contract-kinds","GET");
  }

  static getProfileInfos = () => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("profile/" + macAddress,"GET");
  }

  static postProfileInfos = (payload) => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("edit-profile/" + macAddress,"POST", payload);
  }

  static postAdviceEmailNotification = (payload) => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("advice-email-notification/" + macAddress,"POST", payload);
  }

}