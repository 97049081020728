import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
    CheckboxStyled
    , InputBlockContent
    , CheckboxWithLabelContainer
    , InputLabel
    , InputStyled
    , InputButton
    , SelectStyled
    , TextAreaStyled
    , InputPropTypes
    , InputWrapper
    , RadioStyled
} from './input.parts';


const I18n = {t: text => text}

function Checkbox({
    label
    , styles
    , content
    , disabled
    , name
    , position
    , 'data-validation': validation
    , ...props
}) {
    const full_label = content ?
        (
            <React.Fragment>
                {label + ' '} {content}
            </React.Fragment>
        )
        : label;

    return (
        <CheckboxWithLabelContainer css={styles} position={position}>
            <InputLabel htmlFor={name} label={full_label} css={disabled ? {} : { cursor: 'pointer' }} >
                <CheckboxStyled id={name} name={name} disabled={disabled} {...props} />
            </InputLabel>
        </CheckboxWithLabelContainer>
    );
}

Checkbox.propTypes = {
    ...InputPropTypes
    , content: PropTypes.any
    , label: PropTypes.string.isRequired
    , position: PropTypes.oneOf(['left', 'right'])
    , value: PropTypes.bool
};

function Radio({
    label
    , styles
    , choice
    , content
    , disabled
    , name
    , position
    , 'data-validation': validation
    , ...props
}) {
    const id = `${name}_${choice}`;
    const full_label = content ?
        (
            <React.Fragment>
                {label + ' '} {content}
            </React.Fragment>
        )
        : label;

    return (
        <CheckboxWithLabelContainer css={styles} position={position}>
            <InputLabel htmlFor={id} label={full_label} css={disabled ? {} : { cursor: 'pointer' }} >
                <RadioStyled id={id} name={name} disabled={disabled} choice={choice} {...props} />
            </InputLabel>
        </CheckboxWithLabelContainer>
    );
}

Radio.propTypes = {
    ...InputPropTypes
    , choice: PropTypes.string.isRequired
    , content: PropTypes.any
    , label: PropTypes.string.isRequired
    , position: PropTypes.oneOf(['left', 'right'])
    , value: PropTypes.string.isRequired
};

function Input({ content, children, ...props }) {
    return (
        <InputWrapper {...props}>
            <InputStyled {...props} />
            <InputBlockContent content={content} />
            {children}
        </InputWrapper>
    );
}

Input.propTypes = {
    ...InputPropTypes
    , children: PropTypes.any
    , content: PropTypes.any
    , type: PropTypes.string
};

function InputPassword({ value, ...props }) {
    const default_type = 'password';
    const [input_type, set_input_type] = useState('password');

    function toggle_password_visibility() {
        set_input_type(input_type === default_type ? 'text' : default_type);
    }

    return (
        <InputWrapper value={value} {...props}>
            <InputStyled type={input_type} value={value} {...props} />
            <InputButton onClick={toggle_password_visibility} >
                <span className={`icon ${default_type === input_type ? 'icon-eye' : 'icon-eye-slash'}`} />
            </InputButton>
        </InputWrapper>
    );
}

InputPassword.propTypes = {
    ...InputPropTypes
};


function Select({ options, children, content, value, ...props }) {
    return (
        <InputWrapper {...props} value={value}>
            <SelectStyled value={value || ''} {...props} >
                <option value='' disabled>
                    {I18n.t('choisir une option')}
                </option>
                {options.map(option =>
                    <option key={option.value} value={option.value}>
                        {option.text}
                    </option>
                )}
            </SelectStyled>
            <InputBlockContent content={content} />
            {children}
        </InputWrapper>
    );
}

Select.propTypes = {
    ...InputPropTypes
    , children: PropTypes.any
    , content: PropTypes.any
    , options: PropTypes.array.isRequired
};

function TextArea(props) {
    return (
        <InputWrapper {...props}>
            <TextAreaStyled {...props} />
        </InputWrapper>
    );
}

TextArea.propTypes = {
    ...InputPropTypes
};

export {
    InputPropTypes
    , Checkbox
    , Input
    , InputPassword
    , Radio
    , Select
    , TextArea
};
