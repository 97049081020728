import React, {useState} from "react";

import "./weatherTable.component.css";
import WeatherDayComponent from "./WeatherDayComponent/weatherDay.component";
import WeatherIntervalComponent from "./WeatherIntervalComponent/weatherInterval.component";
import { useScreenResolution, SCREEN_RESOLUTIONS } from "src/Utils/screen_resolution.hook";


function WeatherTableComponent({ dailyPrevisions }) {

  const screenResolution = useScreenResolution();
  const [isOpened, setIsOpened] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedDayIntervals, setSelectedDayIntervals] = useState([]);

  function displayDayDetail(day) {
    if (selectedDay !== day) {
      setSelectedDay(day);
      setIsOpened(true);
    } else {
      setSelectedDay();
      setIsOpened(!isOpened);
    }

    const selectedDayPrevision = dailyPrevisions.find(dailyPrevision => dailyPrevision.date === day);
    setSelectedDayIntervals(selectedDayPrevision.intervalPrevisions);
  }

  return (
    <div className="subContent">
      {dailyPrevisions.length === 7
        ? <div>
            <div className="weatherTable">
              {dailyPrevisions.map(
                dailyPrevision => (
                  <div key={dailyPrevision.day} className="dailyPrevisionContainer">
                    <WeatherDayComponent
                      key={dailyPrevision.date}
                      dailyPrevision={dailyPrevision}
                      selectedDay={selectedDay}
                      displayDayDetail={() => displayDayDetail(dailyPrevision.date)}
                    />

                    <div className="weatherTableDetail">
                      {dailyPrevision.date === selectedDay && screenResolution === SCREEN_RESOLUTIONS.SMALL_SCREEN && selectedDayIntervals.length === 8 &&
                        selectedDayIntervals.map(
                          interval => (
                            <WeatherIntervalComponent
                              key={interval.startHourInterval}
                              dayInterval={interval}
                            />
                          )
                        )
                      }
                      {dailyPrevision.date === selectedDay && screenResolution === SCREEN_RESOLUTIONS.SMALL_SCREEN && selectedDayIntervals.length !== 8 &&
                        <div className="noIntervalPrevisions">Nous ne disposons pas de données suffisantes pour ce jour.</div>
                      }
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="weatherTableDetail">
              {isOpened && screenResolution === SCREEN_RESOLUTIONS.BIG_SCREEN && selectedDayIntervals.length === 8 &&
                selectedDayIntervals.map(
                  interval => (
                    <WeatherIntervalComponent
                      key={interval.startHourInterval}
                      dayInterval={interval}
                    />
                  )
                )
              }
              {isOpened && screenResolution === SCREEN_RESOLUTIONS.BIG_SCREEN && selectedDayIntervals.length !== 8 &&
                <div className="noIntervalPrevisions">Nous ne disposons pas de données suffisantes pour ce jour.</div>
              }
            </div>
          </div>
        : <div className="weatherTable">Nous ne disposons pas des données nécessaires au traitement de cette information.</div>
      }
    </div>
  );
}

export default WeatherTableComponent;