import { getPreviousMonth } from 'src/Utils/date';


function formatResponseToTilesList(response) {
  let tilesList = []
  if (response) {
    tilesList = response.map(t => (
      {
        id: t.tile.id,
        title: t.tile.title,
        description: t.tile.description,
        displayPage: t.tile.display_page,
        category: t.tile.category,
        type: t.tile.type,
        actions: t.tile.action ? t.tile.action.map(action => (
          {
            id: action.id,
            value: action.value,
            adviceLink: action.adviceLink
          }
        )) : [],
        descriptionValue: t.description_value
      }
    ));
  }
  else {
    tilesList = null
  }

  return tilesList
}

/**
 * Met à jour la description d'une tuile en ajoutant les valeurs dans le texte
 */
function updateDescriptionWithValues(description, descriptionValue){
  return  description
              .replace(/#userRate/g, Math.round(Math.abs(descriptionValue)))
              .replace(/#extraCostMinPower/g, Math.round(Math.abs(descriptionValue)))
              .replace(/#lastMonth/g, getPreviousMonth());
}

/**
 * Ouvre une popUp centrée avec les paramètres fournis
 */
function openCenteredPopup(url, titlePage, width, height) {
  const popupWidth = width || 320;
  const popupHeight = height || 320;
  const windowLeft = window.screenLeft || window.screenX;
  const windowTop = window.screenTop || window.screenY;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2 ;
  const popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
  const popup = window.open(url, titlePage, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
  if(popup){
    popup.focus();
  }
  return true;
}

export { formatResponseToTilesList, updateDescriptionWithValues, openCenteredPopup }
