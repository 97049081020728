import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import styled from '@emotion/styled/macro';
import iconSelect from '../images/icon-select.png';

import { LabelWithFirstLetterUppercase, TextWithFirstLetterUppercase } from './text';

function translate(text) {
    return text
}

const I18n = { t: text => text }

const HiddenInput = styled('input')({
    appearance: 'none'
});

const CheckboxTemplate = styled('div')(({ checked, disabled }) => ({
    lineHeight: '20px'
    , padding: '10px'
    , backgroundPosition: '0 center'
    , backgroundImage: `url("/images/icon-checkbox-${checked ? 'on' : 'off'}-transparent.png")`
    , backgroundColor: disabled ? '#eff0f1' : '#fff'
    , opacity: disabled ? 0.5 : 1
    , backgroundRepeat: 'no-repeat'
    , userSelect: 'none'
    , cursor: disabled ? 'normal' : 'pointer'
}));

function CheckboxStyled({ value, onChange, name, disabled, ...props }) {
    function on_change() {
        !disabled && onChange({ target: { value: !value, name } });
    }

    return (
        <React.Fragment>
            <CheckboxTemplate disabled={disabled} checked={value} onClick={on_change} />
            <HiddenInput type='checkbox' id={name} name={name} value={value} onChange={on_change} {...props} />
        </React.Fragment>
    );
}

CheckboxStyled.propTypes = {
    disabled: PropTypes.bool
    , name: PropTypes.string.isRequired
    , onChange: PropTypes.func.isRequired
    , value: PropTypes.bool.isRequired
};

const RadioTemplate = styled('div')(({ checked, disabled }) => ({
    lineHeight: '20px'
    , padding: '10px'
    , backgroundPosition: '0 center'
    , backgroundImage: `url("/images/icon-radio-${checked ? 'on' : 'off'}.png")`
    , backgroundColor: disabled ? '#eff0f1' : '#fff'
    , opacity: disabled ? 0.5 : 1
    , backgroundRepeat: 'no-repeat'
    , userSelect: 'none'
    , cursor: disabled ? 'normal' : 'pointer'
}));

function RadioStyled({ id, choice, value, onChange, name, disabled, ...props }) {
    function on_change() {
        !disabled && value !== choice && onChange({ target: { value: choice, name } });
    }

    const checked = choice === value;

    return (
        <React.Fragment>
            <RadioTemplate disabled={disabled} checked={checked} onClick={on_change} />
            <HiddenInput type='radio' id={id} name={name} value={choice} checked={checked} onChange={on_change}
                {...props}
            />
        </React.Fragment>
    );
}

RadioStyled.propTypes = {
    choice: PropTypes.string.isRequired
    , disabled: PropTypes.bool
    , id: PropTypes.string.isRequired
    , name: PropTypes.string.isRequired
    , onChange: PropTypes.func.isRequired
    , value: PropTypes.string.isRequired
};

const InputStyled = (styled('input')({
    flexBasis: '100%'
    , border: '1px solid #e8e8e8'
    , padding: '4px 10px 3px'
    , fontSize: '13px'
    , verticalAlign: 'middle'
    , fontFamily: '"Centure Gothic", sans-serif'
    , '&[type="number"]': {
        'MozAppearance': 'textfield'
    }
    , '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        'WebkitAppearance': 'none'
    }
}));

const SelectStyled = styled('select')({
    appearance: 'button'
    , cursor: 'pointer'
    , borderRadius: '2px'
    , paddingEnd: '20px'
    , paddingStart: '2px'
    , userSelect: 'none'
    , border: '1px solid #e8e8e8'
    , backgroundColor: '#fff'
    , backgroundImage: `url(${iconSelect})`
    , backgroundPosition: '100% center'
    , backgroundSize: '27px'
    , backgroundRepeat: 'no-repeat'
    , padding: '0 0 0 6px'
    , textOverflow: 'ellipsis'
    , flexBasis: '100%'
    , display: 'flex'
    , flex: '1 0'
    , height: '27px'
    , 'option[value=""][disabled]': {
        display: 'none'
    }
    , '&:required:invalid': {
        color: 'gray'
    }
});

const TextAreaStyled = styled('textarea')({
    height: '80px'
    , flexBasis: '100%'
    , border: '1px solid #e8e8e8'
    , padding: '4px 10px 3px'
    , fontFamily: 'Century Gothic,sans-serif'
    , fontSize: '13px'
});

const BigTextAreaStyled = styled('textarea')({
    height: '150px'
    , width: '260px'
    , flexBasis: '100%'
    , border: '1px solid #e8e8e8'
    , padding: '4px'
    , margin: '10px 0 20px 0'
    , fontFamily: 'Century Gothic,sans-serif'
    , fontSize: '13px'
    , '@media screen and (max-height: 490px)': {
        '&': {
            marginBottom: '10px'
        }
    }
});

const InputGroup = styled('div')({
    display: 'flex'
    , flexFlow: 'nowrap'
    , flex: '1 0'
    , position: 'relative'
});

const InputWithLabelContainer = styled('div')(() => ({
    display: 'flex'
    , alignItems: 'center'
    , position: 'relative'
    , flexFlow: 'nowrap'
    , '& label': {
        textAlign: 'right'
        , marginRight: '2%'
    }
    , '@media screen and (max-width: 500px)': {
        '&': {
            flexFlow: 'wrap'
        }
        , '& label': {
            textAlign: 'left'
        }
        , [`& label, & ${InputGroup}`]: {
            flexBasis: '100%'
        }
    }
    , '@media screen and (min-width: 501px)': {
        '& label': {
            width: '23%'
        }
    }
}));

const CheckboxWithLabelContainer = styled('div')(({ position = 'left' }) => ({
    marginBottom: '15px'
    , position: 'relative'
    , display: 'flex'
    , alignItems: 'center'
    , flexFlow: 'nowrap'
    , ...position === 'left'
        ? { flexDirection: 'row-reverse', textAlign: position }
        : { flexDirection: 'row', textAlign: 'right' }
    , '& label': {
        ...position === 'left'
            ? { flex: '1 100%', margin: '0 0 0 2%' }
            : { flex: 'none', margin: '0 2% 0 0' }
    }
    , '@media screen and (max-width: 500px)': {
        '& label': {
            textAlign: 'left'
            , ...position === 'left'
                ? { marginLeft: '15px' }
                : {}
        }
    }
}));

const InputAppend = styled('span')({
    flexBasis: '80px'
    , fontSize: '15px'
    , textAlign: 'center'
    , border: '1px solid #e8e8e8'
    , borderLeft: 0
    , lineHeight: '24px'
    , display: 'flex'
    , alignItems: 'center'
    , justifyContent: 'center'
    , '*': {
        display: 'flex'
        , alignItems: 'center'
        , justifyContent: 'center'
    }
});

const InputLegend = styled(InputAppend)({
    '.icon-spinner': {
        color: 'inherit'
        , display: 'inline-block'
    }
});

function InputBlockContent({ content }) {
    if (content) {
        const has_icon = is.string(content) && content.match('icon-');
        return (
            <InputLegend>
                {has_icon
                    ? <span className={`icon ${content}`} />
                    : content
                }
            </InputLegend>
        );
    }
    return null;
}

InputBlockContent.propTypes = {
    content: PropTypes.any
};

const InputButton = styled(InputAppend)({
    cursor: 'pointer'
});

const InputIcon = styled('span')(({ color, is_shown }) => ({
    color
    , padding: '0 5px'
    , lineHeight: '26px'
    , fontSize: '15px'
    , visibility: is_shown ? 'visible' : 'hidden'
}));

function is_object_properties_filled(object) {
    return Object.values(object).every(
        v => is.string(v) && is.not.empty(v)
    );
}

function InputFeedback({ value, error_list = [], required, is_dirty, is_subject_to_validation, disabled }) {
    const is_value_provided = (is.string(value) && is.not.empty(value))
        || (is.object(value) && is_object_properties_filled(value))
        || is.number(value);

    if (required || is_subject_to_validation) {
        const is_valid = error_list.length === 0;
        const color = is_value_provided
            ? is_valid ? '#97bf0d' : 'red'
            : 'inherit';
        const icon = is_value_provided
            ? is_valid ? 'icon-coche' : 'icon-cross'
            : required ? 'icon-pencil' : 'icon-coche';
        // When the field is neither required nor filled
        // but subject to validation, we have to show an invisible coche
        // in order to preserve proportions when input is finally filled

        const is_shown = !disabled && (
            is_value_provided
                ? is_valid || is_dirty
                : required || !is_valid
        );

        return (
            <InputIcon color={color} is_shown={is_shown} className={icon} />
        );

    }
    return null;
}

InputFeedback.propTypes = {
    disabled: PropTypes.bool
    , error_list: PropTypes.array
    , is_dirty: PropTypes.bool
    , is_subject_to_validation: PropTypes.bool
    , required: PropTypes.bool
    , value: PropTypes.any
};

const InputHelperText = styled(TextWithFirstLetterUppercase)(({ is_shown }) => ({
    fontSize: '11px'
    , fontWeight: '300'
    , breakBefore: 'always'
    , paddingRight: '25px'
    , textAlign: 'right'
    , visibility: is_shown ? 'inherit' : 'hidden'
    , minHeight: '15px'
    , marginBottom: 0
    , color: '#FF0000'
}));

function InputHelper({ value, error_list = [], required, is_dirty, is_subject_to_validation, disabled }) {
    const is_value_provided = (is.string(value) && is.not.empty(value))
        || (is.object(value) && is_object_properties_filled(value))
        || is.number(value);

    if (required || is_subject_to_validation) {
        const is_valid = error_list.length === 0;
        const is_shown = !disabled && (
            is_value_provided
                ? is_valid || is_dirty
                : required && is_dirty
        );

        const helping_text = is_value_provided
            ? is_valid ? '' : error_list.map(translate).join(',')
            : required ? I18n.t('this field is required') : '';

        return (
            <InputHelperText is_shown={is_shown}>
                {helping_text}
            </InputHelperText>
        );

    }
    return null;
}

InputHelper.propTypes = {
    disabled: PropTypes.bool
    , error_list: PropTypes.array
    , is_dirty: PropTypes.bool
    , is_subject_to_validation: PropTypes.bool
    , required: PropTypes.bool
    , value: PropTypes.any
};

function InputLabel({ label, children, ...props }) {
    if (label) {
        return (
            <React.Fragment>
                <LabelWithFirstLetterUppercase {...props}>
                    {label}
                </LabelWithFirstLetterUppercase>
                {children}
            </React.Fragment>
        );
    }
    return null;
}

InputLabel.propTypes = {
    children: PropTypes.any
    , label: PropTypes.any
};

const InputPropTypes = {
    styles: PropTypes.object
    , 'data-validation': PropTypes.object
    , disabled: PropTypes.bool
    , label: PropTypes.any
    , name: PropTypes.string.isRequired
    , onChange: PropTypes.func.isRequired
    , required: PropTypes.bool
    , value: PropTypes.oneOfType([
        PropTypes.string
        , PropTypes.number
    ])
};

function InputWrapper({
    styles
    , children
    , 'data-validation': validation
    , disabled
    , label
    , required
    , value
}) {
    return (
        <React.Fragment>
            <InputWithLabelContainer css={styles}>
                <InputLabel label={label} />
                <InputGroup>
                    {children}
                    <InputFeedback
                        required={required}
                        disabled={disabled}
                        value={value}
                        {...validation}
                    />
                </InputGroup>
            </InputWithLabelContainer>
            <InputHelper
                required={required}
                disabled={disabled}
                value={value}
                {...validation}
            />
        </React.Fragment>
    );
}

InputWrapper.propTypes = {
    children: PropTypes.any
    , 'data-validation': PropTypes.object
    , disabled: PropTypes.bool
    , label: PropTypes.string
    , required: PropTypes.bool
    , styles: PropTypes.object
    , value: PropTypes.any
};

export {
    CheckboxStyled
    , InputStyled
    , RadioStyled
    , SelectStyled
    , TextAreaStyled
    , BigTextAreaStyled
    , InputGroup
    , InputWithLabelContainer
    , CheckboxWithLabelContainer
    , InputBlockContent
    , InputButton
    , InputFeedback
    , InputLabel
    , InputIcon
    , InputPropTypes
    , InputWrapper
};
