import styled from '@emotion/styled/macro';

const TextWithEllipsis = styled('p')({
    textOverflow: 'ellipsis'
    , overflow: 'hidden'
    , whiteSpace: 'nowrap'
});

const TextWithFirstLetterUppercase = styled('p')({
    '&:first-letter': { textTransform: 'uppercase' }
});
const LabelWithFirstLetterUppercase = TextWithFirstLetterUppercase.withComponent('label');
const DivWithFirstLetterUppercase = TextWithFirstLetterUppercase.withComponent('div');

const Title = styled('p')({
    color: '#212020'
    , fontWeight: 'bold'
    , textTransform: 'uppercase'
    , fontSize: '16px'
    , borderBottom: '1px solid #99989a'
    , paddingBottom: '4px'
    , marginBottom: '20px'
    , textAlign: 'left'
});

const SubTitle = styled(Title)({
    color: '#6d6d6d'
    , fontSize: '12px'
});

const TopTitle = styled('p')({
    position: 'relative'
    , textTransform: 'uppercase'
    , backgroundColor: '#e5e5e5'
    , lineHeight: '38px'
    , fontWeight: 'bold'
    , margin: '0'
});

const LittleTitle = styled('p')({
    color: '#6d6d6d'
    , fontSize: '14px'
    , marginRight: '75%'
    , textAlign: 'right'
    , '@media screen and (max-width: 500px)': {
        marginRight: '0'
        , textAlign: 'left'
    }
});

const MinimalistTitle = styled('p')({
    fontSize: '16px'
    , textTransform: 'uppercase'
    , fontWeight: 700
    , marginBottom: '30px'
});

const Link = styled('a')({
    cursor: 'pointer'
    , color: '#6d6d6d'
    , textDecoration: 'underline'
    , '&:hover': {
        color: '#000'
    }
});

export {
    LabelWithFirstLetterUppercase
    , DivWithFirstLetterUppercase
    , TextWithFirstLetterUppercase
    , TextWithEllipsis
    , Title
    , SubTitle
    , TopTitle
    , LittleTitle
    , MinimalistTitle
    , Link
};
