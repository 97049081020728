import React, { useState } from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./userOpinion.component.css";

import { BigTextAreaStyled } from "src/Utils/input.parts";
import { SmallButton } from 'src/Utils/button';
import { useMutationUserOpinion } from "../../store/user-opinion/user-opinon.service";


const OPINION_BOX = "opinionBox"
const OPINION_MODAL = "opinionModal"

function UserOpinionComponent() {

  const [isOpinionBoxOpened, setIsOpinionBoxOpened] = useState(true);
  const [isOpinionModalOpened, setIsOpinionModalOpened] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [userComment, setUserComment] = useState("");

  const { mutate: postUserOpinion, isLoading } = useMutationUserOpinion({
    onError: () => toast.warning("Problème lors de l'envoi de votre commentaire."),
    onSuccess: () => toast.success("Merci pour votre retour. Votre avis a bien été envoyé.") && closeOpinionBoxOrModal(OPINION_MODAL)
  });

  function closeOpinionBoxOrModal(type) {
    if (type === OPINION_BOX) {
      setIsOpinionBoxOpened(false);
    }
    else if (type === OPINION_MODAL) {
      setIsOpinionModalOpened(false);
      setIsOpinionBoxOpened(true);
    }
  }

  function displayOpinionModal() {
    setIsOpinionModalOpened(true);
    setIsOpinionBoxOpened(false);
  }

  function checkThumb(event) {
    setIsChecked(event.target.checked);
  }

  function handleComment(event) {
    setUserComment(event.target.value);
  }

  function submitUserOpinion(event) {
    event.preventDefault();

    const urlPage = window.location.href.split("/")
    const data = {
      page: urlPage[urlPage.length - 2],
      happy: isChecked ? 1 : 0,
      comment: userComment
    }

    postUserOpinion(data);
  }

  return (
    <div>
      {isOpinionBoxOpened &&
        <div className="userOpinion">
          <span className="closeOpinionBox" onClick={() => closeOpinionBoxOrModal(OPINION_BOX)}>
            <i className="icon-cross"></i>
          </span>
          <div className="contentOpinionBox" onClick={() => displayOpinionModal()}>
            <p>Votre avis nous intéresse</p>
            <div className="thumbs"><i className="icon-thumbs-up"></i><i className="icon-thumbs-down"></i></div>
          </div>
        </div>
      }
      {isOpinionModalOpened &&
        <form onSubmit={submitUserOpinion} >
          <div className="userOpinionModal">
            <span className="closeOpinionBox" onClick={() => closeOpinionBoxOrModal(OPINION_MODAL)}>
              <i className="icon-cross"></i>
            </span>
            <div className="title">Votre avis nous intéresse !</div>

            <div className="thumbsZone">
              <input type="checkbox" id="check" onChange={checkThumb} checked={isChecked}></input>
              <label className="thumbsModal loadcheck" htmlFor="check">
                <span className="entypo-check"><i className="icon-thumbs-up"></i></span>
                <span className="entypo-unlike"><i className="icon-thumbs-down"></i></span>
              </label>
            </div>

            <label>N'hésitez pas à nous laisser un commentaire : </label>
            <BigTextAreaStyled minLength="4" maxLength="500" value={userComment} onChange={handleComment}></BigTextAreaStyled>

            <SmallButton disabled={isLoading} type='submit'>envoyer</SmallButton>
          </div>
        </form>}
    </div>
  );
}

export default UserOpinionComponent;