function isChartDataExist(chartData) {

  /* dataset[1].data : tableau contenant les données du graphique pour chaque mois de l'année en cours */
  let data;
  data = chartData ? chartData.dataset[1].data : [];

  let isChartDataEmpty = true;

  data.forEach(element => {
    if (element != null) {
      isChartDataEmpty = false;
    }
  });

  return !isChartDataEmpty;
}

export default isChartDataExist;