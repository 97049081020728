import { useQuery } from "react-query";

import CommunityApi from "./community.api";

function useQueryCommunity(filter, subFilter) {
  const { data, ...query } = useQuery(
    ['useQueryCommunity', filter, subFilter],
    () => CommunityApi.getCommunityData(filter, subFilter),
  )

  return {
    ...query,
    communityData: data
  }
}


export {
  useQueryCommunity
};