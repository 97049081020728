import { fetchRequest } from "../api";

export default class TilesApi {

  static getTiles = () => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("box/" + macAddress + "/tiles","GET");
  }

  static deleteTile = (tileId) => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("box/" + macAddress + "/tiles/" + tileId,"DELETE");
  }

}