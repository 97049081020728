import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useMutationAdviceEmailNotification } from '../../../store/profile/profile.service';

import "./profileCheckbox.component.css";


function ProfileCheckbox({ checkboxData }) {

  const [isChecked, setIsChecked] = useState(checkboxData.owner.is_advice_email_notification);

  const { mutate: postAdviceEmailNotification } = useMutationAdviceEmailNotification({
    onError: () => toast.warning("Il y a eu une erreur, merci d'essayer à nouveau."),
    onSuccess: () => toast.success("Vos données ont bien été enregistrées."),
  });

  function handleEmailReception(event) {
    setIsChecked(event.target.checked);
    postAdviceEmailNotification({ adviceEmailNotification: event.target.checked });
  }

  return (
    <div className="button">
      <input type="checkbox" className="checkbox" onChange={handleEmailReception} checked={isChecked} disabled={checkboxData.isEditable === false} />
      <div className="knobs">
        <span className="icon-navicon"></span>
      </div>
      <div className="layer"></div>
    </div>
  );
}

export default ProfileCheckbox;