import React from 'react';
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import "src/app.css";
import { useForm } from 'src/Utils/form.hook';
import { is_number, is_required } from 'src/Utils/input.utils';
import { Input } from 'src/Utils/input.component';
import { Title, LittleTitle } from 'src/Utils/text';
import { Button } from 'src/Utils/button';
import { SelectHeatingKind } from './selectHeatingKind.component';
import { SelectHotWaterKind } from './selectHotWaterKind.component';
import { SelectCookingKind } from './selectCookingKind.component';
import { SelectHomePresence } from './selectHomePresence.component';
import { SelectElectricityProvider } from './selectElectricityProvider.component';
import { SelectElectricityContractKind } from './selectElectricityContractKind.component';
import { SelectSolarOrientation } from './selectSolarGeneratorOrientation.component';
import { SelectInverterType } from './selectInverterType.component';
import { useMutationProfileInfos } from '../../../store/profile/profile.service';


function profile_to_form(profileData) {

  if (profileData.profile_created_at) {

    const countHourIntervalHollows = profileData.electricity_contract.hour_interval_hollows.length;
    const profileBox = {
      houseSurface: profileData.house_surface,
      residentsNumber: profileData.residents_number,
      heatingEnergySource: profileData.electricity_reference_variables.heating_energy_source,
      hotWaterEnergySource: profileData.electricity_reference_variables.hot_water_energy_source,
      cookingEnergySource: profileData.electricity_reference_variables.cooking_energy_source,
      dayHomeActivity: profileData.day_home_activity ? "1" : "0",
      electricityProvider: profileData.electricity_contract.electricity_provider.id,
      subscribedPower: profileData.electricity_contract.subscribed_power,
      basicPrice: profileData.electricity_contract.basic_price,
      electricityContractKind:
        profileData.electricity_contract.electricity_contract_kind.code !== "H_pleines_H_creuses"
          ? profileData.electricity_contract.electricity_contract_kind.id
          : "-2",
      startIntervalOne:
        countHourIntervalHollows > 0 ? (profileData.electricity_contract.hour_interval_hollows[0].start_interval) : "",
      endIntervalOne:
        countHourIntervalHollows > 0 ? (profileData.electricity_contract.hour_interval_hollows[0].end_interval) : "",
      priceIntervalOne:
        countHourIntervalHollows > 0 ? (profileData.electricity_contract.hour_interval_hollows[0].price) : "",
      startIntervalTwo:
        countHourIntervalHollows > 1 ? (profileData.electricity_contract.hour_interval_hollows[1].start_interval) : "",
      endIntervalTwo:
        countHourIntervalHollows > 1 ? (profileData.electricity_contract.hour_interval_hollows[1].end_interval) : "",
      priceIntervalTwo:
        countHourIntervalHollows > 1 ? (profileData.electricity_contract.hour_interval_hollows[1].price) : "",
      pvPower: profileData.solar_installation.pv_power,
      orientation: profileData.solar_installation.orientation,
      powerInverter: profileData.solar_installation.power_inverter
    }
    return profileBox;
  }
}

function ProfileForm({ profileData }) {

  const { useField, handle_submit } = useForm(profile_to_form(profileData));
  const macAddress = sessionStorage.getItem("macAddress");


  const queryClient = useQueryClient();
  const { mutate: postProfileInfos } = useMutationProfileInfos({
    onError: () => toast.warning("Problème de connexion au serveur."),
    onSuccess: () => {
      toast.success("Vos données ont bien été enregistrées.") && (window.location.href = "/#/Stats/" + macAddress);
      queryClient.invalidateQueries('useQueryProfileInfos');
    }
  });

  const surfaceInput = useField("houseSurface")
    .check(is_number)
    .check(is_required)

  const residentsNumberInput = useField("residentsNumber")
    .check(is_number)
    .check(is_required)

  const heatingKindSelect = useField("heatingEnergySource")
    .check(is_required)

  const hotWaterKindSelect = useField("hotWaterEnergySource")
    .check(is_required)

  const cookingKindSelect = useField("cookingEnergySource")
    .check(is_required)

  const dayHomeActivitySelect = useField("dayHomeActivity")
    .check(is_required)

  const electricityProviderSelect = useField("electricityProvider")
    .check(is_required)

  const subscribedPowerInput = useField("subscribedPower")
    .check(is_number)
    .check(is_required)

  const basicPriceInput = useField("basicPrice")
    .check(is_number)
    .check(is_required)

  const electricityContractKindSelect = useField("electricityContractKind")
    .check(is_required)

  const solarGeneratorPowerInput = useField("pvPower")
    .check(is_number)
    .check(is_required)

  const solarGeneratorOrientationSelect = useField("orientation")
    .check(is_required)

  const inverterTypeSelect = useField("powerInverter")

  const otherElecProviderInput = useField('otherElecProvider')
  const otherElecContractKindInput = useField('otherElecContractKind')
  const startIntervalOneInput = useField('startIntervalOne')
  const endIntervalOneInput = useField('endIntervalOne')
  const priceIntervalOneInput = useField('priceIntervalOne')
  const startIntervalTwoInput = useField('startIntervalTwo')
  const endIntervalTwoInput = useField('endIntervalTwo')
  const priceIntervalTwoInput = useField('priceIntervalTwo')

  return (
    <form onSubmit={handle_submit(submitProfileInfos)}>
      <Title>ma maison</Title>
      <Input
        type="number"
        min="1"
        max="10000"
        label="surface du logement"
        content='m²'
        {...surfaceInput}
      />
      <Input
        type="number"
        min="0"
        max="100"
        label="nombre de résidents (en moyenne sur l'année)"
        content='pers.'
        {...residentsNumberInput}
      />
      <SelectHeatingKind {...heatingKindSelect} />
      <SelectHotWaterKind {...hotWaterKindSelect} />
      <SelectCookingKind {...cookingKindSelect} />
      <SelectHomePresence {...dayHomeActivitySelect} />

      <Title>mon fournisseur d'énergie</Title>
      <SelectElectricityProvider {...electricityProviderSelect} />
      {electricityProviderSelect.value === '-1' ?
        <Input
          label="précisez votre fournisseur"
          required
          {...otherElecProviderInput}
        />
        : null}
      <Input
        type="number"
        min="3"
        max="36"
        step="1"
        label="Puissance souscrite"
        content='kVA'
        {...subscribedPowerInput}
      />
      <Input
        type="number"
        min="0"
        max="5"
        step="0.01"
        label="Tarif de base"
        content='€/kWh'
        {...basicPriceInput}
      />
      <SelectElectricityContractKind {...electricityContractKindSelect} />
      {electricityContractKindSelect.value === '-1' ?
        <Input
          label="précisez le type de contrat"
          required
          {...otherElecContractKindInput}
        />
        : null}
      {electricityContractKindSelect.value === '-2' ?
        <div>
          <LittleTitle>Plage heures creuses 1</LittleTitle>
          <Input
            type="time"
            label="Heure de début"
            {...startIntervalOneInput}
          />
          <Input
            type="time"
            label="Heure de fin"
            {...endIntervalOneInput}
          />
          <Input
            type="number"
            min="0"
            max="5"
            step="0.01"
            label="Tarif"
            content='€/kWh'
            {...priceIntervalOneInput}
          />
          <LittleTitle>Plage heures creuses 2 <i>(si existante)</i></LittleTitle>
          <Input
            type="time"
            label="Heure de début"
            {...startIntervalTwoInput}
          />
          <Input
            type="time"
            label="Heure de fin"
            {...endIntervalTwoInput}
          />
          <Input
            type="number"
            step="0.01"
            min="0"
            max="5"
            label="Tarif"
            content='€/kWh'
            {...priceIntervalTwoInput}
          />
        </div>
        : null}

      <Title>ma production d'énergie</Title>
      <Input
        type="number"
        step="0.1"
        min="0"
        max="40"
        label="puissance des panneaux solaires (en kWc, ex. : 5.8)"
        content='kWc'
        {...solarGeneratorPowerInput}
      />
      <SelectSolarOrientation {...solarGeneratorOrientationSelect} />
      <SelectInverterType {...inverterTypeSelect} />

      <Button style={{ marginBottom: "75px" }} type='submit' disabled={profileData.isEditable === false}>Valider</Button>
    </form>
  )

  function submitProfileInfos(data) {
    console.log('data :>> ', data);
    if (data.startIntervalOne && data.endIntervalOne) {
      data.firstInterval = {
        startInterval: data.startIntervalOne,
        endInterval: data.endIntervalOne,
        price: data.priceIntervalOne
       };
    } else {
      delete data.firstInterval;
    }
    if (data.startIntervalTwo && data.endIntervalTwo) {
      data.secondInterval = {
        startInterval: data.startIntervalTwo,
        endInterval: data.endIntervalTwo,
        price: data.priceIntervalTwo
       };
    } else {
      delete data.secondInterval;
    }

    delete data.startIntervalOne
    delete data.endIntervalOne
    delete data.priceIntervalOne
    delete data.startIntervalTwo
    delete data.endIntervalTwo
    delete data.priceIntervalTwo

    const isUncorrectlyFilled = Object.values(data).some((value) => value === "");
    const isAllFilled = Object.keys(data).length >= 11;

    if (!isUncorrectlyFilled && isAllFilled) {
      postProfileInfos(data);
    } else {
      toast.warning("remplir tous les champs du formulaire.")
    }
  }

}

export default ProfileForm;