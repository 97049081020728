import { saveAs } from 'file-saver';

import { getEnvironmentV3orV4 } from '../Utils/boxes.version';


const getApiUrl = () => {
  const url = window.location.href;
  return getEnvironmentV3orV4(url).API_URL;
}

const redirectOnError = (statusCode) => {
  switch (statusCode) {
    case 401:
      window.location.href = "/#/Unauthenticated";
      break;
    case 403:
      window.location.href = "/#/403";
      break;
    case 404:
      window.location.href = "/#/ErrorServer";
      break;
    case 406:
      window.location.href = "/#/FormIncomplete";
      break;
    case 500:
      window.location.href = "/#/ErrorServer";
      break;
    default:
  }
}


export const fetchRequest = (endpoint, method, body, params) => {
  const headers = { 'content-type': 'application/json' }
  let queryParams = '';
  const config = {
    credentials: "include",
    method: method,
    headers: {
      ...headers
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  if (params) {
    queryParams = '?' + (new URLSearchParams(params)).toString();
  }

  return window
    .fetch(`${getApiUrl()}${endpoint}${queryParams}`, config)
    .then(async response => {
      redirectOnError(response.status);

      let contentDisposition = response.headers.get('content-disposition');
      if (response.ok && !contentDisposition) {
        return await response.json();
      }
      else if (response.ok && contentDisposition){
        let fileName = contentDisposition.split('=').pop();
        let blob = await response.blob();

        saveAs(blob, fileName);
      }
      else {
        const errorMessage = await response.text()
        throw new Error(errorMessage);
      }
    });
}