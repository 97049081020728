import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';


const spin = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`
const BigLoader = styled.div`
    color: #97bf0d;
    font-size: 100px;
    line-height: 0;
    margin-top: 30%;
    margin-bottom: 150px;
    animation: ${spin} 2s linear infinite;
`

function Loader({ loading }) {
    return loading ? <BigLoader><span className="icon-spinner92"></span></BigLoader> : null;
};

Loader.propTypes = {
    loading: PropTypes.bool.isRequired
};

export default Loader;