import React, { useState, createContext, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

const SMALL_SCREEN = '@media screen and (max-width: 768px)';
const BIG_SCREEN = '@media screen and (min-width: 769px)';

const SCREEN_RESOLUTIONS = {
  SMALL_SCREEN: 'S'
  , MEDIUM_SCREEN: 'M'
  , BIG_SCREEN: 'L'
};

function getScreenResolution() {
  return window.innerWidth < 769
          ? SCREEN_RESOLUTIONS.SMALL_SCREEN
          : window.innerWidth < 950
            ? SCREEN_RESOLUTIONS.MEDIUM_SCREEN
            : SCREEN_RESOLUTIONS.BIG_SCREEN;
}

function useScreenResolution() {
  const resolution = useContext(ResolutionContext);
  return resolution;
}

const ResolutionContext = createContext(getScreenResolution);

function ResolutionContextProvider({ children }) {

  const [screen_resolution, setScreenResolution] = useState(getScreenResolution());

  useEffect(() => {

    function handleResize() {
      setScreenResolution(getScreenResolution());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ResolutionContext.Provider value={screen_resolution}>
      {children}
    </ResolutionContext.Provider>
  );
}

ResolutionContextProvider.propTypes = {
  children: PropTypes.any
};

export {
  ResolutionContextProvider
  , useScreenResolution
  , ResolutionContext
  , SCREEN_RESOLUTIONS
  , SMALL_SCREEN
  , BIG_SCREEN
};

