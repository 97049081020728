import { useQuery, useMutation } from "react-query";

import ProfileApi from "./profile.api";


function useQueryProfileInfos() {
  const { data, ...query } = useQuery(
    ['useQueryProfileInfos'],
    () => ProfileApi.getProfileInfos(),
    { staleTime: Infinity }
  )

  return {
    ...query,
    profileInfos: data
  }
}

function useQueryElectricityProviders() {
  const { data, ...query } = useQuery(
    ['useQueryElectricityProviders'],
    () => ProfileApi.getElectricityProviders(),
    { staleTime: Infinity }
  )

  return {
    ...query,
    electricityProviders: data
  }
}

function useQueryElectricityContractKinds() {
  const { data, ...query } = useQuery(
    ['useQueryElectricityContractKinds'],
    () => ProfileApi.getElectricityContractKinds(),
    { staleTime: Infinity }
  )

  return {
    ...query,
    electricityContractKinds: data
  }
}

function useMutationAdviceEmailNotification(options) {
  return useMutation(
    ['useMutationAdviceEmailNotification'],
    (payload) => ProfileApi.postAdviceEmailNotification(payload),
    options
  )
}


function useMutationProfileInfos(options) {
  return useMutation(
    ['useMutationProfileInfos'],
    (payload) => ProfileApi.postProfileInfos(payload),
    options
  );
}


export {
  useQueryProfileInfos,
  useQueryElectricityProviders,
  useQueryElectricityContractKinds,
  useMutationProfileInfos,
  useMutationAdviceEmailNotification
};