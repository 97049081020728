import styled from "@emotion/styled/macro";

import { BIG_SCREEN } from "src/Utils/screen_resolution.hook";

const MenuContainer = styled("div")({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#eceded",
  color: "black",
  zIndex: 1000
});

const MenuUl = styled("ul")({
  height: "55px",
  margin: 0,
  marginLeft: "-40px",
  display: "flex",
  flexDirection: "row",
  [BIG_SCREEN]: {
    height: "70px"
  }
});

const MenuLi = styled("li")(({ selected }) => ({
  textAlign: "center",
  textTransform: "uppercase",
  listStyle: "none",
  flex: 1,
  borderLeft: "1px solid #fff",
  cursor: "pointer",
  [BIG_SCREEN]: {
    borderBottom: "1px solid #fff"
  },
  fontSize: "12px",
  backgroundColor: selected ? "#9FBF0D" : "inherit",
  color: selected ? "white" : "",

  "& a, a:hover": {
    textDecoration: "none",
    color: "inherit",
    borderColor: "inherit",
    display: "block",
    height: "55px",
    paddingTop: "6px",
    [BIG_SCREEN]: {
      paddingTop: "12px",
      height: "70px"
    }
  },
  "& :first-of-type": {
    border: "medium none"
  }
}));

export { MenuContainer, MenuUl, MenuLi };
