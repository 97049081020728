import React from 'react';

import { Select } from 'src/Utils/input.component';
import { useQueryElectricityProviders } from '../../../store/profile/profile.service';

function SelectElectricityProvider({ ...props }) {

    const { electricityProviders, isLoading } = useQueryElectricityProviders();

    function formatEletricityProviders(electricityProviders) {
        const options = electricityProviders.map(e => ({ value: e.id, text: e.code }))
        options.push({ value: '-1', text: 'Autre' });
        return options;
    }

    return (
        <div>
            {!isLoading &&
                <Select
                    label="fournisseur d'électricité"
                    options={formatEletricityProviders(electricityProviders)}
                    {...props}
                />
            }
        </div>
    )
}

export { SelectElectricityProvider };