import React from "react";
import { Link } from "react-router-dom";

import "./errorPages.component.css";


function UnauthorizedPage() {

  return (
    <div className="infoCard">
      <div className="titleCard">accès refusé</div>
      <div className="detailCard">
        <p>Pour accéder à cette page votre profil doit être complété.</p>
        <p>Merci de remplir le formulaire sur la page PROFIL.</p>
      </div>
      <Link className="linkButton" to="/Profile">ok</Link>
    </div>
  );
}

export default UnauthorizedPage;
