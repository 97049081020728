import React, { useState } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

import "./dataExportModal.component.css";

import { SmallButton } from 'src/Utils/button';
import { useMutationDataExportFile } from "../../store/stats/stats.service";
import { diffDays } from "src/Utils/date";
import Loader from "src/Utils/loader";


function DataExportModalComponent({ isOpened, closeModal }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isValidPeriod, setIsValidPeriod] = useState(true);

  const { mutate: downloadDataExportFile, isLoading } = useMutationDataExportFile({
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success("Le fichier a bien été récupéré."),
  });

  function handleStartDate(event) {
    setStartDate(event.target.value);
  }

  function handleEndDate(event) {
    setEndDate(event.target.value);
  }

  function submitDataExport(event) {
    event.preventDefault();

    let params = {
      startDate: startDate,
      endDate: endDate,
    };

    if (diffDays(startDate, endDate) > 365) {
      setIsValidPeriod(false);
    } else {
      downloadDataExportFile(params);
      closeModal();
    }
  }

  return (
    isOpened
    ? ReactDOM.createPortal(
          <div className="modalOverlay">
            <form onSubmit={submitDataExport}>
              <div className="modalContainer">
                <div className="modal">
                  <div className="modalCloseButtonContainer">
                    <button
                      type="button"
                      className="modalCloseButton"
                      onClick={closeModal}
                    >
                      <span>&times;</span>
                    </button>
                  </div>
                  <div className="modalHeader">
                    <h3>Export des données en CSV</h3>
                  </div>
                  <div className="modalBody">
                    <div className="modalInput">
                      <label>
                        Date début :
                        <input type="date" onChange={handleStartDate} value={startDate} />
                      </label>
                      <label>
                        Date fin :
                        <input type="date" onChange={handleEndDate} value={endDate} />
                      </label>
                    </div>
                    {!isValidPeriod && <span className="errorMessage">La période doit être inférieure à 365 jours.</span>}
                  </div>
                  {isLoading
                    ? <Loader loading={true} />
                    : <div className="modalFooter">
                        <SmallButton grey onClick={closeModal}>annuler</SmallButton>
                        <SmallButton type='submit'>télécharger</SmallButton>
                      </div>
                  }
                </div>
              </div>
            </form>
          </div>,
        document.body
      )
    : null
  );
}

export default DataExportModalComponent;