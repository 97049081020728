import React from 'react';

import { Select } from 'src/Utils/input.component';
import { useQueryElectricityContractKinds } from '../../../store/profile/profile.service';


function SelectElectricityContractKind({ ...props }) {

    const { electricityContractKinds, isLoading } = useQueryElectricityContractKinds()

    function formatElectricityContractKinds(electricityContractKinds) {
        const options = electricityContractKinds.map(e => ({ value: e.id, text: e.code }));
        options.push(
            {
                value: '-2',
                text: 'Heures pleines / Heures creuses'
            },
            {
                value: '-1',
                text: 'Autre'
            }
        );
        return options;
    }


    return (
        <div>
            {!isLoading &&
                <Select
                    label="type de contrat"
                    options={formatElectricityContractKinds(electricityContractKinds)}
                    {...props}
                />
            }
        </div>
    )
}

export { SelectElectricityContractKind };