import { useQuery, useMutation } from "react-query";

import StatsApi from "./stats.api";

function useQueryStats(filter, subFilter) {
  const { data, ...query } = useQuery(
    ['useQueryStats', filter, subFilter],
    () =>  StatsApi.getAnnualData(filter, subFilter),
  )

  return {
    ...query,
    statsData: data
  }
}

function useMutationDataExportFile(options) {
  return useMutation(
    ['useMutationDataExportFile'],
    (params) => StatsApi.postDataExportFile(params),
    options
  );
}


export {
  useQueryStats,
  useMutationDataExportFile
};