import styled from '@emotion/styled/macro';
import { SCREEN_RESOLUTIONS } from 'src/Utils/screen_resolution.hook';

const NavText = styled('span')(({ active = false, screen_resolution }) => ({
  fontSize: '14px'
  , display: screen_resolution !== SCREEN_RESOLUTIONS.BIG_SCREEN && !active ? 'none' : 'inline-block'
}));

const StyledLink = styled('a')({
  color: '#3a3e39'
  , display: 'block'
  , textDecoration: 'none'
  , borderColor: 'inherit'
  , '&.active': {
    backgroundColor: '#fff'
    , '&:before': {
      content: '""'
      , position: 'absolute'
      , top: 0
      , left: 0
      , right: 0
      , backgroundColor: '#83bf0d'
      , height: '3px'
    }
  }
});

const NavLi = styled('li')({
  flexGrow: 1
  , lineHeight: '52px'
  , textTransform: 'uppercase'
  , position: 'relative'
  , listStyle: 'none'
  , textAlign: 'center'
  , '&:hover': {
    backgroundColor: '#ebebeb'
  }
})

const NavUl = styled('ul')({
  display: 'flex'
  , flexDirection: 'row'
  , margin: 0
  , padding: 0
  , justifyContent: 'space-around'
  , '@media only screen and (min-width: 769px)': {
    margin: '0 0 0 25%'
    , justifyContent: 'space-around'
  }
  , 'span': {
    verticalAlign: 'middle'
    , '&.active': {
      color: '#83bf0d'
    }
  }
})

const Icon = styled('span')({
  fontSize: '18px'
  , marginRight: '4px'
})

const NavContainer = styled('div')({
  background: '#e5e5e5'
})

const BetaIcon = styled('img')({
  width: '28px'
  , margin: '5px 0px'
})

const BoxTitle = styled('span')({

})

const NavBoxType = styled('li')(({ screen_resolution }) => ({
  flexGrow: 1
  , lineHeight: '52px'
  , textTransform: 'uppercase'
  , position: 'relative'
  , listStyle: 'none'
  , textAlign: 'center'
  , color: '#e5e5e5'
  , '&:hover': {
    backgroundColor: '#e5e5e5'
  }
  , display: screen_resolution === SCREEN_RESOLUTIONS.SMALL_SCREEN ? 'none' : 'inherit'
}))

export {
  BetaIcon
  , NavContainer
  , NavUl
  , NavLi
  , NavText
  , Icon
  , StyledLink
  , BoxTitle
  , NavBoxType
}