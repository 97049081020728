import clearSky from "src/images/clearSky.png";
import cloudy from "src/images/cloudy.png";
import overcast from "src/images/overcast.png";
import rainy from "src/images/rainy.png";
import night from "src/images/night.png";


const WEATHER_ICONS = {
  clear_sky: clearSky,
  cloudy: cloudy,
  overcast: overcast,
  rainy: rainy,
  night: night,
}

const COLOR_BY_PRICE_DAY = {
  high: 'highPrice',
  middle: 'middlePrice',
  low: 'lowPrice',
}

const COLOR_BY_PRICE_INTERVAL = {
  high: 'high',
  middle: 'middle',
  low: 'low',
}

function formatResponseToDailyPrevisions(response) {
  let dailyPrevisions = [];

  if (!response) {
    return dailyPrevisions;
  }

  dailyPrevisions = response.map(dailyPrevision => (
    {
      day: dailyPrevision.day,
      date: dailyPrevision.date,
      weather: {
        name: dailyPrevision.weather.name,
        value: dailyPrevision.weather.value
      },
      morningPrice: dailyPrevision.morning_price,
      afternoonPrice: dailyPrevision.afternoon_price,
      nightPrice: dailyPrevision.night_price,
      intervalPrevisions: dailyPrevision.interval_previsions ? dailyPrevision.interval_previsions.map(interval => (
        {
          startHourInterval: interval.start_hour_interval,
          price: interval.price_category,
          weather: {
            name: interval.weather.name,
            value: interval.weather.value
          }
        }
      )) : [],
    }
  ));

  return dailyPrevisions;
}

export { WEATHER_ICONS, COLOR_BY_PRICE_DAY, COLOR_BY_PRICE_INTERVAL, formatResponseToDailyPrevisions }