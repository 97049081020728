import React from "react";

import "./errorPages.component.css";
import coachCouls from "src/images/coach-couls.png";


function WattingPage403() {

  return (
    <div className="infoCard">
      <div className="titleCard">Information</div>
      <div className="detailCard">
        <div>
          <img src={coachCouls} alt="Coach"></img>
        </div>
        <span>Le coach énergétique travaille sur vos données.<br></br>Il sera prêt demain.</span>
      </div>
    </div>
  );
}

export default WattingPage403;
