import { fetchRequest } from "../api";

export default class StatsApi {

  static getAnnualData = (filter, subFilter) => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("annual-" + filter + "-" + subFilter + "/" + macAddress, "GET");
  }

  static postDataExportFile = (params) => {
    const macAddress = sessionStorage.getItem("macAddress");
    return fetchRequest("data-export/" + macAddress, "POST", null, params);
  }

}