import React from "react";

import coachCouls from "src/images/coach-couls.png";
import WeatherTableComponent from "./WeatherTableComponent/weatherTable.component";
import Loader from "src/Utils/loader";
import { useQueryWeatherPrevisions } from "src/store/weather/weather.service";

function Weather() {

  const { dailyPrevisions, isLoading } = useQueryWeatherPrevisions();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img className="coach-icon" src={coachCouls} alt="Coach Icon"></img>
          <p className="bubble left">
            Regardons ensemble, quels sont les meilleurs moments pour allumer vos appareils dans les prochains jours.
          </p>
        </div>
        {isLoading
          ? <Loader loading={true} />
          : <WeatherTableComponent dailyPrevisions={dailyPrevisions}/>
        }
      </div>
    </div>
  );
}

export default Weather;
