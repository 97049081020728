import styled from '@emotion/styled/macro';

const Button = styled.button`
    cursor: pointer;
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    border-radius: 3px;
    vertical-align: middle;
    padding: 8px 24px;
    background: ${props => 
        props.grey ? 'linear-gradient(#cfcfcf, #b5b5b5)' : 'linear-gradient(#97bf0d, #7f9f10)'};

    &:hover {
        background: ${props =>
            props.grey ? '#b5b5b5' : '#7f9f10'};
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
`;

const SmallButton = styled(Button)`
    font-size: 12px;
    padding: 4px 18px;
    background: ${props =>
        props.grey ? 'linear-gradient(#cfcfcf, #b5b5b5)' : 'linear-gradient(#97bf0d, #7f9f10)'};

    &:hover {
        background: ${props =>
            props.grey ? '#b5b5b5' : '#7f9f10'};
    }
`;


export {
    Button,
    SmallButton
};
