import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts"; //core
import HC_more from "highcharts/highcharts-more"; //module
HC_more(Highcharts); //init module


const ChartOptions = {
  chart: {
    type: "column",
    height: 500,
    backgroundColor: "#F6F6F6"
  },

  title: false,
  legend: {
    align: "center",
    verticalAlign: "bottom",
    x: 0,
    y: 0
  },

  plotOptions: {
    series: {
      dataLabels: {
        enabled: true
      }
    }
  },
  credits: {
    enabled: false
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          chart: {
            type: "bar",
            height: 800
          },
          legend: {
            align: "center",
            verticalAlign: "bottom"
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: "right",
                x: 0
              }
            }
          }
        }
      }
    ]
  }
};

function addColorsToChart(data, filterSelected) {
  if (data) {
    data[0].color = filterSelected.color2;
    data[1].color = filterSelected.color;
  }

  return data;
}

function ChartComponent({ data, months, filterSelected, unitFilterSelected }) {

  return (
    <HighchartsReact
      options={{
        ...ChartOptions,
        series: addColorsToChart(data, filterSelected),
        yAxis: {
          max: unitFilterSelected.code === "percentage" ? 100 : null,
          allowDecimals: false,
          title: {
            text: filterSelected.label + " en " + unitFilterSelected.label
          }
        },
        xAxis: {
          categories: months,
          labels: {
            overflow: "justify"
          }
        },
        tooltip: {
          valueSuffix: unitFilterSelected.shortLabel
        }
      }}
    />
  );
}

export default ChartComponent;
