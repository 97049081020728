function getPreviousMonth(){
  const months = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
  let date = new Date();
  if (date.getMonth() === 0){
    return months[11];
  }
  else {
    return months[date.getMonth()-1];
  }
}

function diffDays(startDate, endDate) {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
}

export {
  getPreviousMonth,
  diffDays
};