import React from 'react';

import { Select } from 'src/Utils/input.component';

const DAY_HOME_ACTIVITY = {
    0: 'Non',
    1: 'Oui'
}

function mapPresenceToOptions(presence) {
    return Object.entries(presence)
        .map(([value, text]) => ({ value, text }))
}

function SelectHomePresence({ options = mapPresenceToOptions(DAY_HOME_ACTIVITY), ...props }) {

    return (
        <Select
            label="je suis à la maison en journée"
            options={options}
            {...props}
        />
    )
}

export { SelectHomePresence };